import React, { useState, useEffect } from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { getLeaderboard } from '../services/home';
import { useTranslation } from 'react-i18next';

const LeaderBoard = () => {
     const [leaderBoardList, setLeaderBoardList] = useState([]);
     const [isSearchExpanded, setIsSearchExpanded] = useState(false);
     const [searchLead, setSearchLead] = useState('');
     const { t } = useTranslation();
     const handleSearchClick = () => {
        // Toggle the state to expand/collapse the search input box
    setIsSearchExpanded((prevIsSearchExpanded) => !prevIsSearchExpanded);
    };
    const handleSearchChange = (event) => {
        setSearchLead(event.target.value);
    };
    const filteredLeads = leaderBoardList.filter((user) =>
        user.name && user.name.toLowerCase().includes(searchLead.toLowerCase())
    );
    useEffect(() => {
        getLeaderboard().then((res) => {
            console.log(res)
            setLeaderBoardList(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    const dummyImage = '/image/dummy.png';
    return (
        <Layouts>
            <div className='home'>
                <div className="maine-div">
                    <div className="container box-wrapper">
                        <div class="header">
                            <div class="row align-items-center">
                                <div class="col-4 text-start">
                                    <div class="logo d-flex">
                                        <a href="#">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div class="col-8 text-end btnSearchBox">
                                    <div class="search" id="btnSearch">
                                        <img src="/image/search.png" alt="Logo" onClick={handleSearchClick}/>
                                    </div>
                                    {isSearchExpanded && (
                                        <input
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        onChange={handleSearchChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class="page-title">
                            <div class="page-title-circle">
                                {/* <span class="qf-icon-list"></span> */}
                                <img src="/image/more.png" alt="Logo" />
                            </div>
                            <span>{t('5')}</span>
                        </div>
                        <div class="card-simple-outer">
                            <ul class="leaderboard-list-wrapper">
                                {
                                    filteredLeads.map((user, index) => {
                                        return <li class="leaderboard-list">
                                            <a href="#" class="d-flex w-100">
                                                <div class="leaderboard-list-pict">
                                                <img src={user?.image || dummyImage} alt="User" />
                                                    <span>{index+1}</span>
                                                </div>
                                                <div class="leaderboard-list-info">
                                                    <h4 class="leaderboard-list-info-title">{user?.name || 'Guest'}</h4>
                                                    <p><b>{t('6')}:</b> {user.totalPoints}  </p> 
                                                </div>
                                                <div class="leaderboard-score">
                                                    <span>
                                                    <img src="/image/tag.png" alt="Tag" /> 
                                                    {user.totalPoints}   
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    })
                                }
                            </ul>
                            {/* <!-- <div class="p-3">
            <a href="#" class="btn btn-lg button-green w-100">More</a>
        </div> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default LeaderBoard