import { RotatingLines } from "react-loader-spinner";

export const Loader = () => {
    return (
      <div style={{ width: "100px", margin: "auto"}} >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="60"
          visible={true}
        />
      </div>
    )  
  
  }