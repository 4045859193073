import axios from "axios";
import baseUrl from "../utils/baseUrl";

export const loginApi = async (userData) => {
    try {
        const response = await axios.post(`${baseUrl}/auth/adminLogin`, userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/auth/sendOtp`, data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/auth/verifyOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getPacks = async (userId) => {
    try {
        if(userId!=null)
        {
            const response = await axios.get(`${baseUrl}/auth/getPacks/${userId}`);
            return response.data;
        }
        else
        {
            const response = await axios.get(`${baseUrl}/auth/getPacks`);
            return response.data;
        }
    } catch (e) {
        throw e.response.data;
    }
};

export const subscribe = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/auth/sub`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getSubStatus = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/auth/getSubStatus`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};