import api from "./interceptor";
import baseUrl from "../utils/baseUrl";
const token = localStorage.getItem('token');

export const getAllQuiz = async (currentLanguage) => {
    try {
        const response = await api.get(`${baseUrl}/quiz/?languageId=${currentLanguage}`,
            { 
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}



export const getAllQuestion = async (quizId) => {
    try {
        const response = await api.get(`${baseUrl}/quiz/question/all/${quizId}`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}



export const getAllLevel = async () => {
    try {
        const response = await api.get(`${baseUrl}/level`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const getAllOpponent = async () => {
    try {
        const response = await api.get(`${baseUrl}/quiz/findOpponent`,
        {
            headers: {'Authorization': `Bearer ${token}` }
        })
    return response.data.data
} catch (e) {
    throw e.response.data
}
}

export const getLeaderboard = async () => {
    try {
        const response = await api.get(`${baseUrl}/quiz/leaderboard`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const getQuize = async (quizId,levelId,currentLanguage) => {
    try {
        const response = await api.get(`${baseUrl}/quiz/${quizId}/${levelId}?languageId=${currentLanguage}`,
        {
            headers: {'Authorization': `Bearer ${token}` }
        })
    return response.data.data
} catch (e) {
    throw e.response.data
}
}

export const getHistory = async (currentLanguage) => {
  try {
    const response = await api.get(
      `${baseUrl}/quiz/history?languageId=${currentLanguage}`,
      {
        headers: {'Authorization': `Bearer ${token}` }
      }
    );
    return response.data.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const getProfile = async () => {
    try {
        const response = await api.get(`${baseUrl}/user/profile`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const updateProfile = async (formData) => { 
    try {
        const response = await api.patch(`${baseUrl}/user/profile`, formData,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const updateScore = async (quizId, levelId, totalPoints) => {
    console.log("heloooo",totalPoints)
    try {
        const response = await api.post(`${baseUrl}/quiz/points/${quizId}/${levelId}`, totalPoints,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const getAllCompetition = async () => {
    try {
        const response = await api.get(`${baseUrl}/competition`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}
export const getOneCompetition = async (id) => {
    try {
        const response = await api.get(`${baseUrl}/competition/${id}`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}

export const enterChampionship = async (request) => {
    try {
        const response = await api.post(`${baseUrl}/competition`, request,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const getCompetitionInfo = async (id) => {
    try {
        const response = await api.get(`${baseUrl}/competition/detail/${id}`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}

export const getCompetitions = async (randomQuestion,currentLanguage) => {
    try {
        const response = await api.get(`${baseUrl}/competition/quiz/${randomQuestion}?languageId=${currentLanguage}`,
        {
            headers: {'Authorization': `Bearer ${token}` }
        })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const updateScoreCompetitions = async (competitionId, totalPoints) => {
    try {
        const response = await api.post(`${baseUrl}/competition/points/${competitionId}`, totalPoints,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const getTopCompetition = async (competitionId) => {
    try {
        const response = await api.get(`${baseUrl}/competition/top/participants/${competitionId}`,
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}

export const getFunFact = async () => {
    try {
        const response = await api.get(`${baseUrl}/auth/getFunFact`)
        return response.data
    } catch (e) {
        throw e.response.data
    }
}

export const unsubscribe = async () => {
    try {
        const response = await api.post(`${baseUrl}/user/unsub/`,"",
            {
                headers: {'Authorization': `Bearer ${token}` }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}