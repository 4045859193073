import {React, useState, useEffect} from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { NavLink, useParams, useLocation, useNavigate   } from 'react-router-dom';
import { getOneCompetition, enterChampionship, getCompetitionInfo,getTopCompetition } from '../services/home';
import { useTranslation } from 'react-i18next';

  
const ChampionshipDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [listTopCompetition, setListTopCompetition] = useState([]);
    const [yourRank, setYourRank] = useState([]);
    const [getRecord, setGetRecord] = useState([]);
    useEffect(() => {
      getTopCompetition(id).then((res) => {
        setListTopCompetition(res.data)
        setYourRank(res.participant)
      }).catch((err) => {
          console.log(err)
      })
    }, [getRecord]);
  

    const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    return (
        <Layouts>
             
            <div className='home'>
                <div className="maine-div">
                    <div className="container box-wrapper">
                        <div class="header">
                            <div class="row align-items-center">
                                <div class="col-4 text-start">
                                    <div class="logo d-flex">
                                        <a href="#">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div class="col-8 text-end btnSearchBox">
                                   
                                </div>
                            </div>
                        </div>
                      
                        {listTopCompetition ? (
                            <>
                            <div class="page-title ">
                                <div class="page-title-circle">
                                    <img src="/image/more.png" alt="Logo" />
                                </div>
                                <span>{t('76')}</span>
                            </div>
                            {yourRank  && yourRank.length > 0? (
                            <div className='your-rank d-flex justify-content-between'>
                                <div className='rank-of-user'>
                                  <b>Your Rank #{yourRank[0].participantRank} </b>
                                </div>
                                <div class="leaderboard-score w-33 text-right duration-Time">
                                    <span>
                                    <img src="/image/chronometer.png" alt="Tag" /> 
                                    {convertSecondsToMinutesAndSeconds(yourRank[0].durationTime)}
                                    
                                    </span>
                                </div>
                            </div>
                            ): (
                              <></>
                              )}
                            <div class="card-simple-outer">
                                <ul class="leaderboard-list-wrapper leaderboard-list-won">
                                <li class="leaderboard-list">
                                                <a href="#" class="d-flex justify-content-between w-100">
                                                   
                                                    <div className='w-33'>
                                                      
                                                        <b className='user-name-comp'>Name</b>
                                                    </div>
                                                    <p className='w-33'><b>{t('6')}</b>  </p> 
                                                    <div class="leaderboard-score w-33 text-right">
                                                    <b>Time</b>
                                                       
                                                        
                                                        
                                                    </div>
                                                </a>
                                            </li>
                                    {
                                        listTopCompetition.map((listing, index) => {
                                            return <li class="leaderboard-list">
                                                <a href="#" class="d-flex justify-content-between w-100">
                                                   
                                                    <div className='w-33'>
                                                        <p className='user-name-comp'>{listing.participant?.name || 'Guest'}</p>
                                                       
                                                    </div>
                                                    <p className='w-33'> {listing.rightScore}  </p> 
                                                    <div class="leaderboard-score w-33 text-right">
                                                        <span>
                                                        <img src="/image/chronometer.png" alt="Tag" /> 
                                                        {convertSecondsToMinutesAndSeconds(listing.durationTime)}
                                                        
                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            </>

                          ) : (
                            <div className='featured-quiz pb-2 text-center custom-campionship'>
                              <img src="/image/soon.png" alt="Logo" className='rocket' />
                              <h1 className='ft-65'>{t('67')}</h1>
                              <p className='cg-wine mt-4'>{t('68')}</p>
                            </div>
                          )}

                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default ChampionshipDetail