import React, { useState } from 'react'
import '../css/bottom.css'
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const BottomNav = (props) => {
  const { t } = useTranslation();
  return (
    <>
    <div class="bottom-spacer" style={{height: "65px"}}></div>
    <div class="bottom-nav">
            <div class="bottom-nav-bg row gx-0 box-wrapper mx-auto container-fluid">
                <div class="col">
                    <div class="nav-box">
                        <NavLink to="/home" className="nav-box-ico">
                            <span class="qf-icon-home pe-3"><img src="/image/home2.png" alt="Avatar"/></span>
                            <span class="text-uppercase" style={{fontSize: "20px"}}>{t('36')}</span>
                        </NavLink>
                    </div>
                </div>
                {/* <div class="col">
                    <div class="nav-box background-primary" style={{borderRadius: "0 10px 0 0"}}>
                        <NavLink to="/championship" className="nav-box-ico" state={props.state}>
                            <span class="qf-icon-replay pe-3"><img src="/image/refresh.png" alt="Avatar"/></span>
                            <span class="text-uppercase" style={{fontSize: "20px"}}>{t('37')}</span>
                        </NavLink>
                    </div>
                </div> */}
            </div>
        </div>
     
    </>

  )
}

export default BottomNav