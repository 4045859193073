import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Profile from '../components/profile'
import PlayGames from '../components/play'
import LossGames from '../components/loss' 
import History from '../components/history' 
import Edit from '../components/editProfile'
import Policy from '../components/policy'
import ModalPlay from '../components/modelPlay'
import WinGames from '../components/win'
import Language from '../components/language'
import Contactus from '../components/contactUs'
import Screen2 from '../components/screen2'
import Screen3 from '../components/screen3'
import AllQuizes from '../components/allQuizes' 
import GameDetails from '../components/gameDetails'
import LeaderBoard from '../components/leaderBoard'
import FindingOpponent from '../components/findingOpponent'
import Login from '../components/login/login'
import Championship from '../components/championship' 
import ChampionshipDetail from '../components/championshipDetail' 
import ChampionshipPlay from '../components/championshipPlay' 
import ChampionshipResult from '../components/championshipResult' 
import Unsub from '../components/login/unsub' 
import Faq from '../components/faq' 
import Terms from '../components/terms' 

const ProtectedRoute = () => {

    return (
        <Routes>
            <Route exact path='/' element={<Screen2/>} /> 
                <Route path='/home' element={<Screen2/>}  />
                <Route path='/levels' element={<Screen3/>}  />
                <Route path='/moregames' element={<AllQuizes/>}  />
                <Route path='/gamedetails' element={<GameDetails/>}  />
                <Route path='/leaderboard' element={<LeaderBoard/>}  />
                <Route path='/finding' element={<FindingOpponent/>}  />
                <Route path='/championship' element={<Championship/>}  />
                <Route path='/championship/detail/:id' element={<ChampionshipDetail/>}  />
                <Route path='/championship/play' element={<ChampionshipPlay/>}  />
                <Route path='/championship/result/:id' element={<ChampionshipResult/>}  />
                

                <Route path='/profile' element={<Profile/>}  />
                <Route path='/play' element={<PlayGames/>}  />
                <Route path='/loss' element={<LossGames/>}  />
                <Route path='/history' element={<History/>}  />
                <Route path='/edit' element={<Edit/>}  />
                <Route path='/policy' element={<Policy/>}  />
                <Route path='/modal' element={<ModalPlay/>}  />
                <Route path='/won' element={<WinGames/>}  />
                <Route path='/language' element={<Language/>}  />
                <Route path='/contactus' element={<Contactus/>}  />
                <Route path='/unsub' element={<Unsub/>}  />
                <Route path='/faq' element={<Faq/>}  />
                <Route path='/terms' element={<Terms/>}  />
        </Routes>
    )

};

export default ProtectedRoute;