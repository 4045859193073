import {React, useState, useEffect} from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { NavLink } from 'react-router-dom';
import { getAllQuiz } from '../services/home';
import { useTranslation } from 'react-i18next';

const AllQuizes = () => {
    const [allQuiz, setAllQuiz] = useState([]);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();

    const handleSearchClick = () => {
    // Toggle the state to expand/collapse the search input box
    setIsSearchExpanded((prevIsSearchExpanded) => !prevIsSearchExpanded);
    };
    const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    };
      // Filter quizzes based on the search term
    const filteredQuizzes = allQuiz.filter((quiz) =>
        quiz.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    useEffect(() => {
         getAllQuiz(localStorage.getItem('selectedLanguage')).then((res) => {
            console.log(res)
          setAllQuiz(res.data)
          
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <Layouts>
            <div className='home'>
                <div className="maine-div">
                    <div className="container box-wrapper">
                        <div class="header">
                            <div class="row align-items-center">
                                <div class="col-4 text-start">
                                    <div class="logo d-flex">
                                        <a href="#">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div class="col-8 text-end btnSearchBox">
                                    <div className="search" id="btnSearch">
                                        <img src="/image/search.png" alt="Logo" onClick={handleSearchClick}/>
                                    </div>
                                    {isSearchExpanded && (
                                        <input
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        onChange={handleSearchChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class="page-title">
                            <div class="page-title-circle">
                                {/* <span class="qf-icon-list"></span> */}
                                <img src="/image/more.png" alt="Logo" />
                            </div>
                            <span>{t('4')}</span>
                        </div>
                        <div class="card-simple-outer">
                            <ul class="quiz-list-wrapper">
                                { 
                                    filteredQuizzes && filteredQuizzes.map((quiz) => {
                                        return <li class="quiz-list" key={quiz.id}>
                                            <NavLink to='/levels' className="d-flex w-100" state={quiz}>
                                                <div class="quiz-list-pict">
                                                    <img src={quiz?.image} alt="Quiz" /> 
                                                </div>
                                                <div class="quiz-list-info">
                                                    <h4 class="quiz-list-info-title">{quiz?.name}</h4>
                                                    <p>{quiz?.description}</p>
                                                </div>
                                            </NavLink>
                                        </li>
                                    })
                                }
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default AllQuizes