import React, { useEffect, useState } from 'react'
import './css/play.css';
import { NavLink, useLocation } from 'react-router-dom';
import Question from './questions';
import { getQuize, updateScore } from '../services/home';
import LossGames from './loss';
import { useTranslation } from 'react-i18next';

const PlayGames = () => {
    const [data,setData] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);
    const location = useLocation();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [opponentScore, setOpponentScore] = useState(0); 
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [quizData,setQuizData] = useState([]);
    const [quizResponse,setQuizResponse] = useState(0);
    const [isScoreCalculated, setIsScoreCalcualted] = useState(false);
    const { t } = useTranslation();
    // console.log("helloo question",location?.state)

    useEffect(() => { 
          // Make the API call
          // console.log(location,"location........")
          getQuize(location?.state.qid.id, location?.state.lavel.id, localStorage.getItem('selectedLanguage'))
            .then((res) => {
              setQuizResponse(res.length);
              const quizData = res && res.map((res, index) => ({
                qno: index+1,
                question: res.title,
                id: res.id,
                quiz_id: res.quizId,
                image: res.image,
                type: res.type,
                options: [
                  { key: '1', value: res.option_1 },
                  { key: '2', value: res.option_2 },
                  { key: '3', value: res.option_3 },
                  { key: '4', value: res.option_4 }
                ],
                correctAnswer: res.correctAnswer,
              }));
              // console.log(quizData)
              setQuizData(quizData);
              setData(true)
            })
            .catch((err) => {
              console.error(err);
            }); 
      }, []);

    const playSound = (answer) => {
      const audio = new Audio(`/sounds/${answer ? "correct" : "wronganswer"}.mp3`); // Use the absolute path to the sound file
      audio.play();
    };

    useEffect(() => {
       
        let timer;
        if (currentQuestion < quizData.length && !quizCompleted) {
          timer = setInterval( () => {
            setTimeLeft( (prevTime) => {
              if (prevTime === 1) { 
                setQuizCompleted(true); 
                clearInterval(timer);
              }
              return (prevTime > 0 ? prevTime - 1 : 60);
            });
          }, 1000);
        }
        return () => {
          clearInterval(timer);
        };
    }, [currentQuestion, quizCompleted,quizData]);

    const circleAnimationProgress = (timeLeft / 60) * 100; 
    const circumference = 2 * Math.PI * 33;  
    const initialOffset = -(circleAnimationProgress / 100) * circumference;

    useEffect(() => {
      if(quizCompleted){ 
        if(userScore === opponentScore){
          setOpponentScore(opponentScore + 1);
        }
        updateScore(location?.state.qid.id, location?.state.lavel.id,{totalPoints: (userScore*location?.state.lavel.perQuestion)}).then(() => {
          setIsScoreCalcualted(true)
        })
      }
    }, [quizCompleted])

    const handleAnswer = (isCorrect, isOpponentCorrect) => {
        if (isCorrect) {
          setUserScore(userScore + 1);
        }
        if(isOpponentCorrect) {
          setOpponentScore(opponentScore + 1);
        }
        
        const totalQuestion = quizData.length -1;
        if(totalQuestion <= currentQuestion) {
          //updateScore(location?.state.qid, location?.state.lavel,{totalPoints: (userScore*10)}).then(() =>{
            
            setQuizCompleted(true);
         // })
        }else {
          const labelForAnswer1 = document.querySelector(`label[for="answer${quizData[currentQuestion].correctAnswer}"]`);
          if(isCorrect  === false){
            // Check if the label element is found
            if (labelForAnswer1) {
              // Add a class to the label element
              labelForAnswer1.classList.add('correct');
            }
            playSound(false)
          }else{
            playSound(true)
          }
          setTimeout(() => {
            setCurrentQuestion(currentQuestion + 1);
            if (labelForAnswer1) {
              // Add a class to the label element
              labelForAnswer1.classList.remove('correct');
            }
          }, 500);
        }    
    };
 
    return (
      <>
      {quizCompleted ? 
        <LossGames userScore={userScore} opponentScore={opponentScore} isScoreCalculated={isScoreCalculated}  state={location?.state}/>:

        <div className='home'>
            <div className="maine-div">
                <div class="page-header-score">
                    <div class="container box-wrapper adjestment"> 
                        <div class="row gx-0">
                            <div class="col-5">
                                <div class="quiz-player">
                                    <div class="quiz-player-avatar"> 
                                        <img src={location?.state?.players?.user?.image ? location?.state?.players?.user?.image : "/image/image 4.png"} alt="Avatar" />
                                    </div>
                                    <div class="quiz-player-info text-start ms-2 mt-2">
                                        <p class="mb-2 role-name">{t('26')}</p>
                                        <p class="mb-0 fw-bold real_score" id="real_score_start">0</p>
                                        <p class="mb-0 fw-bold" id="real_score_show"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="countdown" id="countdown">
                                    <span id="countdown-number">{timeLeft}</span> 
                                    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.5" cx="35" cy="35" r="33" fill="#212529"
                                        style={{
                                          strokeDasharray: `${circumference}px`,
                                          strokeDashoffset: `${initialOffset}px`,
                                          strokeLinecap: "round",
                                          strokeWidth: "4px",
                                          stroke: "#fe9e44",
                                          fill: "#212529",
                                          animation: "countdown 50s linear infinite forwards",
                                        }}
                                        />
                                    </svg>

                                </div>

                            </div>
                            <div class="col-5">
                                <div class="quiz-player">
                                    <div class="quiz-player-info text-end me-2 mt-2">
                                        <p class="mb-2 role-name">{location?.state?.players?.opponent?.name}</p>
                                        <p class="mb-0 fw-bold real_score" id="fake_score_start">0</p>
                                        <p class="mb-0 fw-bold" id="fake_score_show"></p>
                                    </div>
                                    <div class="quiz-player-avatar">
                                        <img src={location?.state?.players?.opponent?.image ? location?.state?.players?.opponent?.image : "/image/image 4.png"} alt="Avatar"/>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
                {/* <p className='available'>Sorry, the quiz is not available at the moment.</p> */}
                {data && quizData.length > 0 && !quizCompleted ?<Question questionData={quizData[currentQuestion]} onAnswer={handleAnswer} totalque={quizResponse}/>:""}
                
                <div class="container box-wrapper">
                     <div class="pt-5 pb-4 text-center">
                        <NavLink to='/levels' className="btn btn-sm button-back" state={location.state.qid}> <img src="/image/arrow_left_alt.png" alt="Avatar"/> {t('30')}</NavLink>
                    </div> 
                </div>
            </div>
            
        </div>
            }
      </>
       
       
    )
}

export default PlayGames