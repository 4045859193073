// Question.js
import React, { useState,useEffect } from "react"; 
import './css/play.css';
import { useTranslation } from 'react-i18next';

const checkContentType = async (url) => {
    try {
      const response = await fetch(url);
      const contentType = response.headers.get('content-type');
  
      if (contentType && contentType.startsWith('image/')) {
        return 'image';
      } else if (contentType && contentType.startsWith('video/')) {
        return 'video';
      } else {
        return 'unknown';
      }
    } catch (error) {
      console.error('Error checking content type:', error);
      return 'error';
    }
  };
  const MyComponent = ({ url,question }) => {
    const [contentType, setContentType] = useState(null);
  
    useEffect(() => {
      const fetchContentType = async () => {
        if (url) {
          const type = await checkContentType(url);
          setContentType(type);
        }
      };
  
      fetchContentType();
    }, [url]);
  
    const renderContent = () => {
      switch (contentType) { 
        case 'image':
          return <div class="multiple-question multiple-question-image">
          {url !== '' ? <img src={url} alt="Image" /> : null}
              <div class="multiple-question-content">
                  <p className='game-que'>{question}</p>
              </div>
          </div>
          
          ;
        case 'video':
         // return <video controls width="50" src={url} />;
          return <div class="multiple-question multiple-question-image">
          <video width="100%" loop="true" autoplay="autoplay" controls="">
              <source src={url} type="video/mp4"/></video>
              <div class="multiple-question-content">
                  <p className='game-que'>{question}</p>
              </div>
          </div> ;
        // case 'unknown':
        //   return <p>Unknown content type</p>;
        // case 'error':
        //   return <p>Error checking content type</p>;
        default:
          return <div class="multiple-question multiple-question-image">
             
              <div class="multiple-question-content">
                  <p className='game-que'>{question}</p>
              </div>
          </div>;
      }
    };
  
    return <div>{renderContent()}</div>;
  };
const Question = ({ questionData, onAnswer,totalque }) => {
  //console.log("answer",questionData.title)
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [opponentAnswer, setOpponentAnswer] = useState(null);
  const [clickedOption, setClickedOption] = useState(null); 
  const [status,setStatus] = useState(false)
  useEffect(() => {  
      setClickedOption(null);
      setSelectedOption(null); 
   
  }, [questionData]);
  
  const handleOptionClick = (option) => {
    //console.log("option",option)  
    setClickedOption(option);
    setSelectedOption(option);
    const isCorrect = option === questionData.correctAnswer;
    setStatus(isCorrect)
    simulateOpponentAnswer()
    onAnswer(isCorrect, opponentAnswer); 
    
    //console.log("opponentAnswer",opponentAnswer)
  };

  const handleSubmit = () => {
    const isCorrect = selectedOption === questionData.correctAnswer;
    
    onAnswer(isCorrect);
  };

  const simulateOpponentAnswer = () => {
    // Simulate opponent answering with a mix of correct and incorrect responses
    const isCorrect = Math.random() < 0.5; // 50% chance of being correct
    if (isCorrect) { 
      setOpponentAnswer(true) 
      //console.log("hellooo")
    } else {
        setOpponentAnswer(false)
        //console.log("hellooo222222")
    //   const incorrectOptions = questionData.options.filter(
    //     (option) => option.key !== questionData.correctAnswer
    //   );
    //   const randomIndex = Math.floor(Math.random() * incorrectOptions.length);
    //   setOpponentAnswer(incorrectOptions[randomIndex] === questionData.correctAnswer);
    }


   };

  return (
    <div class="container box-wrapper">
    <div class="swipe loaded">
        <div class="swipe-action">
            <div class="swipe-action-circle love">
                <span class="qf-icon-thumbs-up"></span>
            </div>
            <div class="swipe-action-circle nope">
                <span class="qf-icon-thumbs-down"></span>
            </div>
        </div>

        <div id="swipe-counter" class="swipe-counter">
            <span id="current" class="count">{questionData?.qno}</span> {t('63')} <span id="maxQuestion">{totalque}</span>
        </div> 
        <div class="multiple">
            <div id="multipleQuestionDisplay">
            <MyComponent  url={questionData?.image} question={questionData?.question} />
                
                </div>
            <ul class="multiple-answer" id="multipleAnswerDisplay"> 
            {questionData.options && questionData.options.map((option, index) => {
               if (option.value) {
                return (
                <li className={`multiple-answer_list choosenAnswer`} data-question_id="905" key={index+1} onClick={() => handleOptionClick(index+1)}>
                      <div class="d-none" id="idChoice">2051</div>
                      <input type="radio" class="2051" name="answer" id={`question${index + 1}`} />
                      <label className={`2051 d-flex align-items-center ${clickedOption === index + 1 ? (selectedOption === questionData.correctAnswer ? 'correct' : 'wrong') : ''}`} for={`answer${index + 1}`} >
                          <span class="bullet pe-2">{index+1}. </span>
                          <div>{option.value}</div>
                          {/* {option.value.length > 40 ? option.value.substring(0, 40) + '...' : option.value} */}
                      </label> 
                  </li>
            ) 
                }
            })}
               

            </ul>
        </div>  
        <input type="hidden" name="qno" id="qno" value="0" />
        <input type="hidden" name="countdown" id="countdown" value="10" />
        <input type="hidden" name="currentQuestion" id="currentQuestion" value="0" />


    </div>
</div>
  );
};

export default Question;
