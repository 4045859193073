import React, { useState, useEffect } from "react";
import "../css/login.css";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate,useLocation} from "react-router-dom";
import ReactCustomFlagSelect from "react-custom-flag-select";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import OtpInput from "react-otp-input";
import {getPacks,subscribe,getSubStatus } from "../../services/auth-api";
import { useTranslation } from "react-i18next";
import {Loader} from "../../utils/loader"

const Register = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState({});
  const [checked, setChecked] = useState(true);
  const [packs, setPacks] = useState([]); // State to store packs data
  const [selectedPack, setSelectedPack] = useState(1);
  const [flow, setFlow] = useState(); // State to track if data is loaded
  const [flowMsg, setFlowMsg] = useState({number:0,error:true});
  const [message, setMessage] = useState({message: "",error: true});
  const query = new URLSearchParams(useLocation().search); 
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));

  const handlePackSelection = (pack) => {
    setSelectedPack(pack);
  };

  useEffect(() => {
      setMessage({ message: "", error: false});
      var userId=null;
      if(query?.get("id"))userId=query?.get("id");
      //console.log("userId "+ userId);
      getPacks(userId).then((res) => {
          //console.log("All packs ", res?.data);
          setPacks(res?.data); // Set packs data
          if(res?.token)
          {
            localStorage.setItem("token", res.token); 
            window.location.href = localStorage.getItem("menu")?"/"+localStorage.getItem("menu"):"/"
          }
        })
        .catch((err) => {
          console.log(err);
        });
  },[]);
  useEffect(() => {
    localStorage.setItem('selectedLanguage',language);
    i18n.changeLanguage(language);
    setMessage({ message: "", error: true});
  },[language]);


  const find = (arr, obj) => {
    return arr.filter((o) => Object.keys(obj).every((i) => obj[i] === o[i]));
  };

  const checkSubStatus = async (input) => {
    console.log("input="+input)
    setMessage({ message: "", error: false});
    if (phone.length>=8) {
        setLoading(true);
        await getSubStatus({ mobileNumber: parseInt(phone) })
          .then((res) => {
            setFlow("EXISTING");  
            if(res.responseCode==200)
            {
              localStorage.setItem("token", res.data.accessToken);
              if(input=="1"){window.location.href ="/home";}
              else setFlowMsg({number:81,error:false}); //already sub and charged ;          
            }
            else if(res.responseCode==203) setFlowMsg({number:100,error:true});//low balance; 
            else if(res.responseCode==202) setFlowMsg({number:101,error:true}); //Charging in process;            
            else setFlow("PACK"); // select pack 

          }).catch((err) => {
            console.log(err);
            setMessage({message: err.error,error: true,});
          }).finally(() => setLoading(false));
    }else {setMessage({ message: t("90"), error: true});}
  };

  const handleSubscription = async () => {
      setMessage({ message: "", error: true});
      if (phone) 
      {
        setLoading(true);
        await subscribe({mobileNumber:phone,packId:selectedPack,languageId:language})
        .then((res) => {
          setFlow("EXISTING");
          setFlowMsg({msgNumber:84,error:false});//New subscription;
        }).catch((err) => {
          console.log(err);
          setMessage({message: err.error,error: true,});
        }).finally(() => setLoading(false));
      }else { console.log("Please select a pack before confirming");}
  };
  const handleChange = async (e) => {
    if (e.target.checked) setChecked(true);
    else setChecked(false);
  };
  const redirectToPage = (page) => {
    navigate(page);
}
  const accessService = async () => {
    window.location.href = localStorage.getItem("menu")?"/"+localStorage.getItem("menu"):"/";   
};

  return (
    <div className="home">
      <div className="maine-div">
        <div className="container-fluid box-wrapper login">          
          <div class="header"> 

             <div class="row align-items-center">
               <div class="col-6 text-start">
                 <div class="logo d-flex">
                   <a href="#">
                     <img src="/image/Logo_new.png" alt="Logo" />
                   </a>
                 </div>
               </div>

               <div class="col-6 text-end">
                   <div className="pt-2">
                     <select
                     class="choose-option"
                     id="dropdown"
                     value={language}
                     onChange={(event) => setLanguage(event.target.value)}
                   >
                     <option class="choose-option" value="0">English</option>
                     <option value="4">Swahili</option>                   
                   </select>
                   </div>
               </div>  
             </div>
           </div>
          
          <div className="login-header pb-4">
            <div className="box-wrapper mx-auto text-center position-relative">
              <span className="modal-level-title game-title">{t("89")}</span>
            </div>
          </div>
          <div className="login-info">
            <div className="login-thumbnail2">
              <img src="/image/login_img.png" alt="Quiz" />
            </div>

            {flow === "PACK" ? (
              <div className="pack-main-container">
                <h3 className="login-title mb-3 mt-3 text-center">{t("79")}</h3> 
                <div className="text-center radio">
                  {packs.map((pack) => (
                   <div key={pack.id} className="pack">
                        <label>
                          <input type="radio" id="rd1" checked={selectedPack === pack.id} onChange={() => handlePackSelection(pack.id)} />
                          {pack.packFrequency=="Monthly"?pack.packCurrency+" "+pack.packPrice + "/" + t("99")+" "+t("96"):
                           pack.packFrequency=="Weekly"?pack.packCurrency+" "+pack.packPrice + "/" + t("98")+" "+t("96"):
                           pack.packCurrency+" "+pack.packPrice + "/" + t("97")+" "+t("96")
                          }

                      </label> <br/><br/>             
                    </div>                    
                  ))}
                </div>
                {message.message && (<div className="mb-3"><p className={`alert alert-${ message.error ? "danger" : "success"}`} > {message.message} </p></div>)}

                {loading ? <Loader />:<><button
                  className="pack-btn btn btn-primary btn-pm-custom"
                  onClick={handleSubscription}>
                  {t("80")}
                </button></>
                }
              </div>
             ) : flow === "EXISTING" ?(
              <>
                <h3 className={`mb-3 mt-3 text-center login-title-${ flowMsg.error ? "red" : "green"}`}>{t(flowMsg.number)}</h3>              
                {loading ? <Loader />:<button className="pack-btn btn btn-primary btn-pm-custom" onClick={() => checkSubStatus("1")} >  { t("83") }</button> }
              </>
            ):(  // New user Flow      
              <>
                <h3 className="login-title mb-3 mt-3 text-center">{t("50")}</h3>
                <br/>                               
                <label>
                  <input value = "test" type = "checkbox" className="customCheckBox" onChange = {handleChange} checked={checked} />
                  <a className="linkBlack" onClick={() => {redirectToPage('/policy')}}>&nbsp;{t("93")}</a>
                  </label>
                  <br/><br/>
                <div className="phone-info text-cente">
                  <div className="phone-number-info">
                   
                    <input
                      className="form-control form-mobile-no register-mobile-no"
                      placeholder={t("78")}
                      type="tel"
                      inputMode="numeric"
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                  </div>  
                  <br/>
                  {message.message && (<div className="mb-3"><p className={`alert alert-${ message.error ? "danger" : "success"}`} > {message.message} </p></div>)}
                  {loading ? <Loader />:<>
                  <button
                    className="pack-btn btn btn-primary btn-pm-custom"
                    onClick={() => checkSubStatus("0")} disabled={checked==false?true:false}>
                    { t("77") }
                  </button>
                  <br/><br/>
                 
                   
                   </>
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
