
import React, { useState } from "react";
import './css/screen3.css';  
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Policy = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));
  return ( 
    <div className='home'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('93')}</span>
                </div>
            </div> 
             <div class="home-profile">
              {language==4?(<>
                <p><strong>QUIZ MASTER&ndash; nyongeza ya sera ya faragha</strong></p>
                <p>Hii nyongeza ya sera ya faragha inatoa taarifa za jumla katika kuchakata taarifa zako binafsi kwa huduma ya QUIZ MASTER, kutoa maelezo zaidi katika&nbsp;<a href="https://www.vodacom.co.tz/privacy">sera kuu ya faragha</a>. Iwapo unatumia huduma ya QUIZ MASTER pamoja na na huduma nyingine, unapaswa kusoma taarifa za faragha za huduma nyingine vilevile.&nbsp;</p>
                <p>Iwapo utakuwa na maswali yoyote katika vipengele vya nyongeza hii ya faragha, tafadhali piga simu 100 au tembelea maduka yetu ya Vodacom au madawati ya huduma kwa wateja na mmojawapo katika timu yetu maalum atakujibu.&nbsp;</p>
                <p><strong>QUIZ MASTER</strong></p>
                <p>Ni huduma ya kidigitali inayomwezesha mteja kushinda pesa taslimu kwa kukusanya pointi nyingi kwa kujibu maswali mbalimbali kwa usahihi kupitia njia ya USSD, My Vodacom App au kupitia tovuti ya huduma hii.</p>
                <p>&nbsp;Takwimu zako zitasindika wakati wa usajili ili kuhifadhi rekodi na kuweza kuonyesha yaliyomo ambayo yanahusiana na usajili wako. Unapovinjari ndani ya lango, data yako itasindika na vifaa unavinjari (kwa mfano, kukumbuka historia yako ya kuvinjari).&nbsp;</p>
                <p><strong>Taarifa binafsi tunazochukua kukuhusu wewe</strong></p>
                <p>Aina ya habari tunayochakata ni pamoja na;</p>
                <ul>
                <li>Nambari yako ya simu na jina lako</li>
                <li>Njia ya malipo yaliyotumika kulipia huduma hii mf. Salio la kawaida au/na M-Pesa</li>
                <li>Tarehe ya kujiunga na huduma hii, tarehe ya kutumia huduma hii, tarehe ya ushindi, tarehe ya kujiondoa na huduma hii.</li>
                <li>Maelezo ya utunzaji wa wateja kupitia mawasiliano yako nasi,</li>
                <li>Habari kuhusu matumizi yako ya huduma, kama vile unatumia mara ngapi au unapokataa taarifa; na</li>
                </ul>
                <p>Tutapata pia habari kuhusu jinsi unavyotumia bidhaa na huduma zetu, kama vile:</p>
                <ul>
                <li>Kiwango cha huduma unayopokea - kwa mfano, makosa ya mtandao au huduma na matukio mengine ambayo yanaweza kuathiri huduma zetu za mtandao au huduma zingine</li>
                <li>Tunakusanya pia habari isiyojulikana ya uchanganuzi juu ya jinsi watumiaji wa huduma ya QUIZ MASTER ili kuboresha uzoefu wako na utatuzi.</li>
                </ul>
                <p><strong>Wakati gani tunachukua taarifa zako binafsi</strong></p>
                <p>Tunachukua taarifa zako binafsi wakati:</p>
                <ul>
                <li>Unapojiunga kwenye huduma kwa mara ya kwanza</li>
                <li>Unapolipia huduma hii</li>
                <li>Unatumia huduma na huduma zake zozote</li>
                <li>Unapowasiliana na timu yetu ya huduma kwa wateja.</li>
                </ul>
                <p><strong>Vodacom itatumia taarifa zako binafsi kwa kuzingatia</strong><strong>: </strong></p>
                <p><strong>Utekelezaji wa mkataba wako au kuingia mkataba na kuchukua hatua kulingana na maombi yako</strong>. Kwa mfano, ili uweze kupiga simu na kuandika ujumbe, na kutumia mtandao katika simu yako, tunachakata vitu kama namba unazopiga, kiasi cha data unachotumia na muda unaozitumia ili tuweze kukuunganisha na mtandao. Hii inatuwezesha kupata bili yako kulingana na matumizi yako. Pia tunahitaji kufanya uchunguzi wa mikopo pale unapofanya maombi ya bidhaa au huduma.</p>
                <p><strong>Maslahi halali ya kibiashara ya Vodacom</strong>, kwa mfano, kuzuia udanganyifu, kuimarisha usalama wa mtandao na huduma, matangazo ya moja kwa moja, na uboreshaji wa huduma zetu. Kila tunapozingatia msingi huu halali kuchakata data zako, tunatathmini maslahi yetu ya biashara kuhakikisha kuwa hayazidi haki zako. Pia, kuna wakati unakuwa na haki ya kukataa hii michakato. Kwa taarifa zaidi, tembelea kipengele cha Haki Zako katika sera hii.</p>
                <p><strong>Utekelezaji wa matakwa ya lazima ya kisheria</strong>, ikiwa ni pamoja na matakwa ya kihasibu na kodi, ambayo yako chini ya sera madhubuti za ndani (kama vile muda wa kutunza taarifa), taratibu, na haki yako ya kuweka mipaka ya matumizi ya data, ambayo inadhibiti wigo wa msaada wa kisheria utakaotakiwa kutolewa; au&nbsp;</p>
                <p><strong>Ridhaa unayotoa</strong> pale ambapo Vodacom haijiegemezi kwenye msingi wowote wa kisheria. Ridhaa hiyo inaweza kuondolewa muda wowote.</p>
                <p><strong>Namna tunavyotumia taarifa zako binafsi</strong></p>
                <p>Tunatumia taarifa zako kwa utoaji wa huduma na kutimiza ununuzi wowote uliofanya:</p>
                <ul>
                <li>kutusaidia kukutambua</li>
                <li>malipo na huduma kwa wateja;</li>
                <li>kutaarifu juu ya mabadiliko yoyote kwenye huduma zetu ambazo zinaweza kukuathiri; na</li>
                <li>uthibitisho wa kitambulisho</li>
                </ul>
                <p><strong>Kutangaza na </strong><strong>Kuunda makundi kulingana na sifa (Profiling)</strong></p>
                <p>Ikiwa umeamua kujiunga kupokea matangazo na umetupa ruhusa ya kutumia data yako kutengeneza kipekee ujumbe kuhusu taarifa hizi, tutatumia mwingiliano wako na Programu hii au programu ingine kukujulisha aina ya matangazo tutakayo kutumia ambayo ni muhimu zaidi kwako. Chagua kujiondoa kwenye ujumbe wa matangazo au makundi kulingana na sifa.&nbsp;</p>
                <p>Ikiwa hautaki tena kupokea ujumbe wa matangazo kutoka Vodacom, unaweza kuchagua kujiondoa kupokea ujumbe wa matangazo yote au kwa njia uliochagua tu (barua pepe, ujumbe wa mfupi wa maandishi, simu au chapisho).</p>
                <p>Kuna njia mbali mbali za kujiondoa:</p>
                <ul>
                <li>Wasiliana na timu yetu ya huduma kwa wateja kwa kupiga 100;</li>
                <li>Ujumbe wetu wote wa matangazo huja na sehemu ya &ldquo;kujiondoa" ambapo unaweza kubofya ili kujiondoa;</li>
                <li>Mwambie mshauri hutaki matangazo tena, ikiwa utapokea simu ya matangazo;</li>
                <li>Tuma barua pepe kwa <a href="mailto:customercare@vodacom.co.tz">customercare@vodacom.co.tz</a> kwa mwongozo</li>
                <li>Kujiondoa hakumaanishi kwamba hautapokea tena ujumbe unaohusiana na huduma, kama vile ujumbe unaokuambia juu ya huduma ulioitaka au vigezo na masharti yetu yanapobadilika. Bado utaendelea kupokea hizo (isipokuwa kama utaonyesha vinginevyo). Unaweza pia kupokea matangazo kupitia taarifa za kushinikiza ikiwa haujazuia kupitia mipangilio yako ya Programu.</li>
                </ul>
                <p><strong>Kuboresha huduma zetu</strong></p>
                <p>Tunachukua taarifa zisizo bainisha majina au taarifa binafsi za jumla ili kuboresha huduma tunazotoa kwa kila mmoja. Hakuna taarifa zozote za uchanganzinazorudi kwako kwa njia yoyote ile.</p>
                <p>Kulinda mitandao yetu na kusimamia idadi za simu, ujumbe na matumizi mengine ya mitandao yetu. Kwa mfano, tunabainisha muda ambao mawasiliano hufanywa kwa wingi ili tuweze kujaribu na kuhakikisha kwamba mitandao inakuwa na uwezo wa kuhimili idadi katika muda huo;</p>
                <p>Kufahamu namna unavyotumia mitandao, bidhaa na huduma zetu. Kwa kufahamu hivyo, tunaweza kupitia upya, kuendeleza na kuboresha mitandao, bidhaa na huduma hizo, kuanzisha bidhaa na huduma nzuri zaidi, pamoja na kukupa bidhaa na huduma zetu maalum kwako.</p>
                <p><strong>Namna tunavyoshirikisha wengine taarifa zako binafsi </strong></p>
                <p>Pale inapohusika, tunashirikiana taarifa kuhusu wewe na:</p>
                <ul>
                <li>Kampuni zilizo katika Vodafone Group</li>
                <li>Washirika au mawakala wanaohusika kukupatia bidhaa na huduma ulizoomba au ulizotumia;</li>
                <li>Makampuni yanayohusika kutoa huduma kwa ajili ya, au kwa niaba ya, Vodacom Tanzania Public Limited Company, Vodacom Group or Vodafone Group;</li>
                <li>Kumbukumbu za mikopo, mawakala/taasisi za kuzuia udanganyifu au taasisi za kutathmini biashara, au taasisi za kutathmini mikopo;</li>
                <li>Mawakala wa kukusanya madeni au taasisi zingine za kukusanya madeni;</li>
                <li>Taasisi za kusimamia sheria, taasisi za serikali, mamlaka za udhibiti, mahakama au mamlaka zingine za umma endapo tutalazimika, au kuruhusiwa na sheria;</li>
                <li>Upande wa tatu au taasisi ambayo taarifa hizo zinahitajika ili kutekeleza sheria yoyote husika, au matakwa yoyote ya kisheria au ya udhibiti;</li>
                <li>Upande wa tatu kwa ajili ya kufanya matangazo kwa ushirikiano na upande huo;</li>
                </ul>
                <p><strong>Kuhamisha taarifa zako binafsi nje ya nchi</strong></p>
                <p>Hatuhamishi taarifa zako binafsi kwenda nje ya Tanzania; taarifa binafsi zote zimehifadhiwa kwenye mifumo inayosimamiwa na Vodacom.</p>
                <p><strong>Ukomo wa muda wa kutunza taarifa zako binafsi </strong></p>
                <p>Tutatunza taarifa zako kwa muda wote ambao sheria itatutaka. Endapo hakuna matakwa ya kisheria, tutazitunza kwa muda ambao tunahitaji tu. Kwa taarifa zaidi ya muda tunaotunza taarifa zako angalia <a href="https://vodacom.co.tz/public/assets/files/Vodacom%20Tz-%20%20Customer%20Privacy%20Statement-retention-schedule.pdf">hapa</a>.</p>
                <p><strong>Kuweka salama taarifa zako binafsi </strong></p>
                <p>Tuna timu maalum za wataalam wa usalama ambazo kila wakati zinapitia na kuboresha njia za kulinda taarifa zako binafsi dhidi ya kupatikana bila idhini, kupotea kwa bahati mbaya, kuvuja au kuharibika.</p>
                <p>Mawasiliano kwa njia ya mtandao (kama barua pepe) hayako salama ikiwa hayakuwekewa ulinzi. Mawasiliano yako yanaweza kupita nchi nyingi kabla ya kufika ulikoyatuma, kwani ndiyo mtandao ulivyo.</p>
                <p>Hatutakubali kuwajibika endapo taarifa binafsi zitapatikana au kupota bila idhini nje ya uwezo wetu wa kudhibiti.</p>
                <p>Kamwe hatutakutaka utupe kwa njia zisizo sahihi taarifa zako binafsi au za akaunti ulizoziwekea ulinzi. Unawajibika kutunza taarifa zako binafsi na za akaunti yako na kutozishirikisha kwa watu wengine.&nbsp;</p>
                <p>Tovuti yetu inaweza kutoa mawasiliano ya kimtandao kwa tovuti zingine. Hatuwezi kuwajibika kwa usalama na maudhui ya tovuti za upande wa tatu. Kwa hiyo hakikisha unasoma sera ya kampuni juu ya faragha na vidakuzi kabla ya kutumia au kuweka taarifa zako binafsi kwenye tovuti zao.&nbsp;</p>
                <p>Masharti haya yanahusika pia kwa tovuti za upande wa tatu au maudhui unayojiunganisha nayo ili kutumia bidhaa na huduma zetu.&nbsp;</p>
                <p>Unaweza kuchagua kutoa taarifa zako kwa njia zingine kama viunganishi vya kijamii (social plug-ins) zikiwemo zile zinazotolewa na Google, Facebook, Twiiter na Pinterest) au kwa kutumia huduma za upande wa tatu ambazo zinakuruhusu kutuma maoni au taarifa nyingine za wazi, na upande wa tatu unaweza kutumia taarifa hizo.&nbsp;</p>
                <p><strong>Haki zako</strong></p>
                <p><strong>Haki ya Kurekebisha taarifa zako binafsi </strong></p>
                <p>Una haki ya taarifa zinazokuhusu kusahihishiwa endapo hazina usahihi. Endapo taarifa zako tulizonazo zitahitaji kubadilishwa, au unadhani si sahihi, unaweza kutembelea maduka yetu au madawati la huduma kwa wateja ili kubadilisha.</p>
                <p><strong>Haki ya kupata taarifa binafsi</strong></p>
                <p>Haki ya kuomba kupata nakala ya taarifa zako ambazo Vodacom inazo. Kuomba taarifa hizi kama mtu binafsi au upande wa tatu ulioidhinishwa, tafadhali tembelea maduka yetu ya Vodacom au madawati ya Huduma kwa Wateja.</p>
                <p><strong>Haki ya kuzuia uchakataji unaoweza kukuathiri</strong></p>
                <p>Una haki ya kuitaka Vodacom kusitisha au kutoanza uchakataji wa taarifa yako binafsi yoyote inayokuhusu ikiwa uchakataji huo unaweza kusababisha madhara makubwa kwako au kwa mtu mwingine.</p>
                <p><strong>Haki ya kuzuia uchakataji wa taarifa binafsi kutumika kwa madhumuni ya biashara</strong></p>
                <p>Una haki, katika hali fulani, kupinga au kumzuia Vodacom kuchakata taarifa zako za kibinafsi kwa madhumuni ya matangazo ya biashara. Ikiwa hutaki tena kupokea ujumbe wa matangazo ya biashara kutoka kwa Vodacom, unaweza kuchagua kujiondoa kwenye mawasiliano yote ya matangazo ya biashara kwa kujiondoa kupitia CPM kwenye Programu ya Myvodacom na tovuti ya Vodacom, barua pepe, SMS, MMS, kupiga simu kwa kituo cha huduma kwa wateja au chapisho).</p>
                <p>Kwa habari zaidi au kutumia haki hii, tafadhali piga simu namba 100 au tembelea maduka yetu ya Vodacom au madawati ya Huduma kwa Wateja.</p>
                <p><strong>Haki kuhusiana na uamuzi unaofanywa Kiotomatiki</strong></p>
                <p>Una haki ya kumtaka Vodacom kuhakikisha kwamba uamuzi wowote unaofanywa na Vodacom au kwa niaba ya Vodacom ambao una athari kubwa kwako, hautegemei uchakataji kwa njia ya kiotomatiki pekee.</p>
                <p><strong>Haki ya kufutiwa, kuzuia au kuharibu taarifa</strong></p>
                <p>Katika mazingira fulani, kwa mfano, tukiwa hatuna tena sababu ya msingi ya kuendelea kushughulika na taarifa zako au hatuhitajiki na sharia kutunza taarifa hizo, unaweza kuomba tuzifute, tuzizuie au kuziharibu.</p>
                <p><strong>Haki ya Fidia</strong></p>
                <p>Una haki ya kulipwa fidia na Vodacom endapo utapata madhara kwa sababu ya ukiukwaji wa masharti ya sharia ya Ulinzi wa taarifa wa binafsi uliofanywa na Vodacom.</p>
                <p><strong>Namna ya kuwasilisha malalamiko</strong></p>
                <p>Endapo una malalamiko, unaweza kuwasiliana na timu yetu ya Huduma kwa Wateja kwa kupiga namba 100 au kwa barua pepe customercare@vodacom.co.tz. Tutajitahidi tuwezavyo kukusaidia lakini ikiwa bado huna furaha, unaweza kuwasiliana na Tume ya ulinzi wa taarifa binafsi.</p>
                <p><strong>Imeboreshwa 25 Agosti 2023</strong></p>
              </>):(<>
              <p><strong>QUIZ MASTER &ndash; privacy supplement</strong></p>
              <p>This privacy supplement gives some general information around the processing conducted for QUIZ MASTER product, to provide further context to our&nbsp;<a href="https://www.vodacom.co.tz/privacy">main privacy policy</a>. If you&rsquo;re using QUIZ MASTER together with another service, you should read the privacy information for the other service too.&nbsp;</p>
              <p>If you have any queries in any aspect of this privacy supplement, please call 100 or Customer Care Service desks and a member of our dedicated team will respond to you.</p>
              <p>&nbsp;<strong>QUIZ MASTER</strong></p>
              <p>A digital service that allow Vodacom customers to invite other Vodacom customers to win various instant prizes by collecting points accumulated from answering various questions accurately and accordingly.&nbsp;</p>
              <p><strong>Personal information we collect about you</strong></p>
              <p><strong>Information we collect about you</strong>&nbsp;</p>
              <p>The types of information we process includes;</p>
              <ul>
              <li>Your name and Mobile number</li>
              <li>Your subscription type and payment channel ie. Airtime and/or M-Pesa</li>
              <li>Customer care information through your contact with us,</li>
              <li>Information about your usage of the App, such as how often you use it or when you dismiss alerts; and</li>
              </ul>
              <p>We&rsquo;ll also get information about how you use our products and services, such as:</p>
              <ul>
              <li>The level of service that you receive &ndash; for example, delivery reports, delays or service faults and other events that may affect our services;</li>
              </ul>
              <p><strong>When we collect your personal information</strong></p>
              <p>We collect your personal information when:</p>
              <ul>
              <li>You first subscribe to the service</li>
              <li>You use the QUIZ MASTER services and any of its features</li>
              <li>You contact our customer care teams with a query.</li>
              </ul>
              <p>Vodacom will process your personal data based on:</p>
              <p><strong>The performance of your contract or to enter into the contract</strong>&nbsp;and to take action on your requests. For example, we&rsquo;ll need to process your network traffic data as part of making your device &ldquo;connected&rdquo; and we&rsquo;ll need to process Administrator's payment details for your monthly billing.&nbsp;</p>
              <p><strong>Vodacom&rsquo;s legitimate business interests</strong>, for example, fraud prevention, maintaining the security of our network and services, direct marketing, and the improvement of our services. Whenever we rely on this lawful basis to process your data, we assess our business interests to make sure they do not override your rights. Additionally, in some cases you have the right to object to this processing. For more information, visit the 'Your rights' section of this policy.&nbsp;</p>
              <p><strong>Compliance with a mandatory legal obligation</strong>, including for example accounting and tax requirements, which are subject to strict internal policies (such as retention periods), procedures, and your right to restrict usage of your data, which control the scope of legal assistance to be provided.&nbsp;</p>
              <p><strong>Consent you provide</strong>&nbsp;where Vodacom does not rely on another legal basis. Consent may be withdrawn at any time. When you give your consent, you will be given details on how to change your mind or visit the 'Your rights' section of this policy for more information.&nbsp;</p>
              <p><strong>How we use your personal information</strong></p>
              <p><strong>To provide you with your service</strong></p>
              <p>We use your information for service provision and to fulfil any purchases you have made:</p>
              <ul>
              <li>to help us identify you and any accounts you hold with us;</li>
              <li>to provide you access to the service</li>
              <li>billing and customer care;</li>
              <li>to notify you of any changes to the services that may affect you; and</li>
              <li>identity verification.</li>
              </ul>
              <p><strong>Marketing and profiling</strong></p>
              <p>If you are opted into marketing and have given us permission to use your data to tailor these communications, we&rsquo;ll use your interactions with this portal to inform the sorts of marketing we should be sending you so that it&rsquo;s the most relevant for you. To opt out of Marketing messages or profiling:</p>
              <p>If you no longer want to receive marketing messages from Vodacom, you can elect to opt out of all marketing communications or only selected methods (email, messaging, phone or post).</p>
              <p>There are various ways to opt out:</p>
              <ul>
              <li>Contact our customer care team by dialing 100;</li>
              <li>All our marketing messages come with an &ldquo;unsubscribe&rdquo; link which you can click to unsubscribe;</li>
              <li>Tell the adviser you no longer want to be marketed to, if you receive an outbound marketing call;</li>
              <li>Email <a href="mailto:customercare@vodacom.co.tz">customercare@vodacom.co.tz</a> for guidance.</li>
              <li>Opting out does not mean that you won&rsquo;t any longer receive service-related messages, such as messages telling you about your order status or when our Terms and Conditions change. You will still continue to receive those (unless we have indicated otherwise). You may also receive marketing via push notifications if you have not turned them off via your App settings.</li>
              </ul>
              <p><strong>Service improvement</strong></p>
              <p>We collect anonymous, de-identified or aggregate information in order to improve the service we offer to everyone. None of these analytics are linked back to you in any way.</p>
              <p>To protect our networks and manage the volumes of calls, texts and other uses of our networks. For example, we identify peak periods of use so we can try and ensure the networks can handle the volume at those times; and</p>
              <p>To understand how you use our networks, products and services. That way we can seek to review, develop and improve these, develop more interesting and relevant products and services, as well as personalizing our products and services.</p>
              <p><strong>How we share your personal information</strong></p>
              <p>Where applicable, we share information about you with:</p>
              <ul>
              <li>Companies in the Vodafone Group</li>
              <li>Partners, suppliers or agents involved in delivering the products and services you&rsquo;ve ordered or used</li>
              <li>Companies who are engaged to perform services for, or on behalf of, Vodacom Tanzania Plc, or Vodacom Group or Vodafone Group</li>
              <li>Credit reference, fraud-prevention or business-scoring agencies, or other credit scoring agencies</li>
              <li>Debt collection agencies or other debt-recovery organizations</li>
              <li>Law enforcement agencies, government bodies, regulatory organizations, courts or other public authorities if we have to, or are authorized to by law</li>
              <li>A third party or body where such disclosure is required to satisfy any applicable law, or other legal or regulatory requirement</li>
              <li>Third parties for joint promotions with that third party. They&rsquo;ll be responsible for their own compliance with applicable privacy laws</li>
              </ul>
              <p><strong>International data transfers</strong></p>
              <p>We do not make international transfers of personal data outside Tanzania;&nbsp;All data is stored on a platform managed by Vodacom.</p>
              <p><strong>How long we keep your personal information for</strong></p>
              <p><strong>To provide the service, billing and for authentication purposes&nbsp;</strong><br /> Personal data detailed in previous sections will be kept for the time necessary to provide the service to the user.&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p><strong>Providing the service&nbsp;</strong></p>
              <p>The data related to the operation data of the service or the network history described above will be kept for 90 days and then will be anonymized or deleted.&nbsp;Personal data related to the user and his network will be kept for the time in which the user has activated the service.&nbsp;They will be removed after 12 months after the user unsubscribes from the service.&nbsp;</p>
              <p><strong>Diagnostics services</strong></p>
              <p>We will collect data in order to enhance your services. The types of data sets and the retention related to these data types can be seen in &ldquo;Personal Information we collect about you&rdquo; section.</p>
              <p>For information about other data retention with Vodacom, please refer to our&nbsp;<a href="https://vodacom.co.tz/public/assets/files/Vodacom%20Tz-%20%20Customer%20Privacy%20Statement-retention-schedule.pdf">retention schedule</a></p>
              <p><strong>Keeping your personal information secure</strong></p>
              <p>We have specialized security teams who constantly review and improve our measures to protect your personal information from unauthorized access, accidental loss, disclosure or destruction.</p>
              <p>Communications over the internet (such as emails) aren&rsquo;t secure unless they&rsquo;ve been encrypted. Your communications may go through a number of countries before being delivered, as this is the nature of the internet.</p>
              <p>We cannot accept responsibility for any unauthorized access or loss of personal information that is beyond our control.</p>
              <p>We&rsquo;ll never ask for your secure personal or account information by an unsolicited means of communication. You&rsquo;re responsible for keeping your personal and account information secure and not sharing it with others.</p>
              <p>Our website may provide links to third-party websites. We cannot be responsible for the security and content of such third-party websites. So make sure you read that company&rsquo;s privacy and cookies policies before using or putting your personal information on their site.</p>
              <p>The same applies to any third-party websites or content you connect to using our products and services.</p>
              <p>You may choose to disclose your information in certain ways such as social plug-ins (including those offered by Google, Facebook, Twitter and Pinterest) or using third-party services that allow you to post reviews or other information publicly, and a third party could use that information.</p>
              <p>Social plug-ins and social applications are operated by the social network themselves and are subject to their own terms of use and privacy and cookies policies. You should make sure you&rsquo;re familiar with these.</p>
              <p><strong>Your rights</strong></p>
              <p>Below we set out details on how you can exercise your rights.&nbsp; If you have a question or cannot find the answer, please visit our or visit our Vodacom shops or Customer Care Service desks for more information or call 100.&nbsp;</p>
              <p><strong>Right to rectify personal data </strong></p>
              <p>You have the right to have information held about you corrected if it is not accurate. If what we hold on you needs updating, or you think it may be inaccurate, you can visit our Vodacom shops or Customer Care Service desks to update it.&nbsp;</p>
              <p><strong>Right to access personal data</strong></p>
              <p>You have the right to make a request for a copy of the personal data that Vodacom holds about you. To make this request as an individual or an authorised third party, visit our Vodacom shops or Customer Care Service desks for more information on how to do this.&nbsp;</p>
              <p><strong>Right to prevent processing likely to affect you or to another person </strong></p>
              <p>You are entitled to require Vodacom to suspend or not to begin, processing of any of your personal data, if the processing of such personal data is likely to cause substantial damage to you or to another person. You can visit our Vodacom shops or Customer Care Service desks you can email us at <a href="mailto:customercare@vodacom.co.tz">customercare@vodacom.co.tz</a> to discuss your rights. In certain circumstances, you will have the right to ask us to restrict the processing&nbsp;</p>
              <p><strong>Right to prevent processing of personal data for direct marketing</strong></p>
              <p>You have the right, in certain circumstances, to object to Vodacom processing your personal information for direct marketing. If you no longer want to receive marketing messages from Vodacom, you can elect to opt out of all marketing communications or only selected methods through CPM on Myvodacom App and Vodacom website, email, SMS, MMS, calling to customer care centre or post).&nbsp;</p>
              <p>For more information or to exercise this right, please call 100 or visit our Vodacom shops or Customer Care Service desks.<strong>&nbsp;</strong></p>
              <p><strong>Rights in relation to automated decision making</strong></p>
              <p>You have the right to require Vodacom to ensure that any decision taken by or on behalf of you which significantly affects you shall not base solely on the processing by automatic means. please let us know and we will review your request<strong>&nbsp;</strong></p>
              <p><strong>Right to erasure, block or destroy</strong></p>
              <p>In some circumstances, such as where we no longer have a justifiable reason to continue to process your data or if not required by the law to retain such data, you can request we delete, block or destroy it.<strong>&nbsp;</strong></p>
              <p><strong>Right to compensation</strong> <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>
              <p>If your rights have been infringed by Vodacom by reason of any contravention of any of the requirements of the Personal Data Protection Act, you are entitled to compensation from Vodacom for that damage.<strong>&nbsp;</strong></p>
              <p><strong>How to lodge a complaint</strong></p>
              <p>If you have a complaint, you can contact us on the details provided. We&rsquo;ll do our best to help but if you&rsquo;re still unhappy, you can contact the Personal Data Protection Commission.</p>
              <p><strong>&nbsp;</strong><strong>Last updated 25th August 2023</strong></p>
              <p><strong>&nbsp;</strong></p>
              <p>&nbsp;</p>
              </>)}  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Policy