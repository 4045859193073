
import React, { useState } from "react";
import './css/screen3.css'; 
import './css/faq.css'; 
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Faq = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));
  return ( 
    <div className='home'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('91')}</span>
                </div>
            </div> 
             <div class="home-profile">
              {language==4?(<>
                <div>
                <p><strong>1. Huduma ya QUIZ MASTER ni nini?</strong></p>
                <p>Huduma hii inamuwezesha mteja kushinda zawadi mbalimbali kwa kujibu maswali mbalimbali kwa usahihi na kukusanya pointi za ushindi.&nbsp;</p>
                <p><strong>2. Ni vigezo gani vya kutumia huduma hii?</strong></p>
                <p>Wateja wote wa Vodacom pekee wanaweza kupata huduma hii.&nbsp;</p>
                <p><strong>3. Ni faida gani mteja atapata akijiunga na Huduma hii?</strong></p>
                <p>Mteja ataweza kushinda zawadi mbalimbali kwa kujibu maswali kwa usahihi na kukusanya pointi nyingi zaidi ya wachezaji wengine. Zawadi za mwezi ni kama zifuatavyo.</p>
                <table>
                <tbody>
                <tr className="tableHeader">
                <td colspan="2">
                <p ><strong>Washindi wa Mwezi</strong></p>
                </td>
                </tr>
                <tr>
                <td>
                <p>Mshindi wa 1</p>
                </td>
                <td>
                <p>Tsh 100,000</p>
                </td>
                </tr>
                <tr>
                <td>
                <p>Washindi 2-5</p>
                </td>
                <td>
                <p>Tsh 50,000</p>
                </td>
                </tr>
                <tr>
                <td>
                <p>Washindi 6-10</p>
                </td>
                <td>
                <p>Tsh 20,000</p>
                </td>
                </tr>
                </tbody>
                </table>
                <p><strong>4. Mteja anawezaje kujiunga na huduma hii?</strong></p>
                <p>Mteja atajiunga kupitia njia zifuatazo;</p>
                <ul>
                <li><strong><u>USSD: </u></strong>Kwa kupigia *149*67# kisha atachagua kujiunga.</li>
                <li><strong><u>My Vodacom App:</u></strong> Kwa kuchagua HUDUMA ZA KIDIJITALI kisha TRIVIA kisha QUIZ MASTER</li>
                <li><strong><u>Tovuti:</u></strong> Kwa bonyeza kiungo kilichotumwa kupitia ujumbe mfupi ambacho ni <a className="link" href="https://vtz.quizzzmaster.com/">https://vtz.quizzzmaster.com/</a>&nbsp;</li>
                </ul>
                <p><strong>5. Huduma hii inagharimu kiasi gani?</strong></p>
                <table>
                <tbody>
                <tr className="tableHeader">
                <td>
                <p><strong>Mpangilio</strong></p>
                </td>
                <td>
                <p><strong>Bei</strong></p>
                </td>
                <td>
                <p><strong>Muda</strong></p>
                </td>
                </tr>
                <tr>
                <td>
                <p><em>Siku</em></p>
                </td>
                <td>
                <p>Tsh 200</p>
                </td>
                <td>
                <p>Siku 1</p>
                </td>
                </tr>
                <tr>
                <td>
                <p><em>Wiki</em></p>
                </td>
                <td>
                <p>Tsh 500</p>
                </td>
                <td>
                <p>Siku 7</p>
                </td>
                </tr>
                <tr>
                <td>
                <p><em>Mwezi</em></p>
                </td>
                <td>
                <p>Tsh 800</p>
                </td>
                <td>
                <p>Siku 30</p>
                </td>
                </tr>
                </tbody>
                </table>
                <p><strong>6. Mteja atajiondoaje na huduma hii?</strong></p>
                <p>Mteja atajiondoa kwenye huduma hii kwa kupiga *149*67# kisha atachagua kujiondoa. Pia mteja anaweza kujiondoa kwenye huduma kwa kupiga *149*83#.</p>
                </div>
              </>):(<>

                <div>
                    <p><strong>1. What is QUIZ MASTER Service?</strong></p>
                    <p>This is a digital service that allows Vodacom customers to win various instant prizes by accumulating winning points from answering questions accurately and accordingly.</p>
                    <p><strong>2. Who is eligible to QUIZ MASTER Service?</strong></p>
                    <p>All Vodacom customers are eligible to use QUIZ MASTER service.</p>
                    <p><strong>3. What benefits can customer find on QUIZ MASTER Service?</strong></p>
                    <p>Customers will be able to win various instant cash and physical prizes. Prizes distribution is as follows.</p>
                    <table width="0">
                    <tbody>
                    <tr className="tableHeader">
                    <td colspan="2">
                    <p><strong>Monthly winners</strong></p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p>1st Winner</p>
                    </td>
                    <td>
                    <p>Tsh 100,000</p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p>2nd - 5th Winner</p>
                    </td>
                    <td>
                    <p>Tsh 50,000</p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p>6th - 10th Winner</p>
                    </td>
                    <td>
                    <p>Tsh 20,000</p>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    <p><strong>4. How can the customers subscribe to QUIZ MASTER Service?</strong></p>
                    <p>Customers can subscribe via the following channels.</p>
                    <ul>
                    <li><strong><u>USSD</u></strong><u>: </u>By dialing *149*67# and choose SUBSCRIBE.</li>
                    <li><strong><u>My Vodacom App</u></strong><u>:</u> By selecting DIGITAL SERVICES then TRIVIA then QUIZ MASTER.</li>
                    <li><strong><u>PORTAL:</u></strong>Customers can subscribe by clicking on the service link <a  href="https://vtz.quizzzmaster.com/">https://vtz.quizzzmaster.com/</a></li>
                    </ul>
                    <p><strong>5. How much does QUIZ MASTER Service charge?</strong></p>
                    <table>
                    <tbody>
                    <tr className="tableHeader">
                    <td >
                    <p><strong>Subscription Plan</strong></p>
                    </td>
                    <td >
                    <p><strong>Price</strong></p>
                    </td>
                    <td >
                    <p><strong>Validity</strong></p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p><em>Daily</em></p>
                    </td>
                    <td>
                    <p>Tsh 200</p>
                    </td>
                    <td>
                    <p>1 day</p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p><em>Weekly</em></p>
                    </td>
                    <td>
                    <p>Tsh 500</p>
                    </td>
                    <td>
                    <p>7 days</p>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <p><em>Monthly</em></p>
                    </td>
                    <td>
                    <p>Tsh 800</p>
                    </td>
                    <td>
                    <p>30 days</p>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    <p><strong>6. How can customer opt-out of QUIZ MASTER Service?</strong></p>
                    <p>Customer can opt by dialing *149*67# or customer can unsubscribe by dialing *149*83#</p>
                    </div>
              </>)}  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Faq