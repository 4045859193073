import React, { useState, useEffect } from "react";
import "../css/login.css";
import { useNavigate,NavLink} from "react-router-dom";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import {unsubscribe} from "../../services/home";
import { useTranslation } from "react-i18next";
import {Loader} from "../../utils/loader"

const Unsub = () => {
  let navigate = useNavigate(); 
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState({});
  const [flow, setFlow] = useState(""); // State to track if data is loaded
  const [message, setMessage] = useState({message: "",error: false,});

  const unsubConfirm = async () => {
        setMessage({ message: "", error: false});
        setLoading(true);
        await unsubscribe().then((res) => {
          console.log(res);
          localStorage.clear();
          setFlow("CONFIRM");
          window.location.href ="/";

        }).catch((err) => {
          console.log(err);
          setMessage({message: err.error,error: true,});
        }).finally(() => setLoading(false));
  };

  const unsubNotConfirm = async () => {     
        navigate("/home");
   };

  return (
    <div className="home">
      <div className="maine-div">
        <div className="container-fluid box-wrapper login">
          <div className="pt-5">
            <div className="login-thumbnail">
              <img src="/image/login.png" alt="Quiz" />
            </div>
          </div>
          <div className="login-header pb-4">
           {/* <div className="box-wrapper mx-auto text-center position-relative">
              <span className="modal-level-title game-title">{t("50")}</span>
            </div>*/}
             <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('8')}</span>
                </div>
          </div>
          <div className="login-info">
            <div className="login-thumbnail2">
              <img src="/image/login_img.png" alt="Quiz" />
            </div>

            {flow === "CONFIRM" ? (
                <h3 className="login-title-green mb-3 mt-3 text-center">{t("86")}</h3>
            ):(
                <div>
                    <h3 className="login-title mb-3 mt-3 text-center">{t("85")}</h3>                 
                    <br/>
                    {message.message && (<div className="mb-3"><p className={`error alert-${ message.error ? "danger" : "success"}`} > {message.message} </p></div>)}

                    {loading ? <Loader />:<>
                    <button className="pack-btn btn btn-primary btn-pm-custom-green"
                    onClick={unsubConfirm}>{t("87")}</button>
                    <button className="pack-btn btn btn-primary btn-pm-custom-red"
                    onClick={unsubNotConfirm}>{t("88")}</button></>
                    }
              </div>  
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsub;
