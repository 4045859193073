import React, { useState } from 'react'
import './css/screen3.css';  
import { NavLink, useLocation, useNavigate  } from 'react-router-dom'; 
import { levels } from '../utils/levels';
import { useTranslation } from 'react-i18next';
const Screen3 = () => { 
    const location = useLocation(); 
    const [quiz, setQuiz] = useState(location?.state);
    const navigate = useNavigate();
    const [quizLevel, setQuizLevel] = useState(levels[0]);
    const handleQuizClick = (quizValue, quizLevelValue) => {
        setQuiz(quizValue);
        setQuizLevel(quizLevelValue);
    };
    const goBack = () => {
        navigate(-1);
    };
    const { t } = useTranslation();
  return ( 
    <div className='home level-page'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <a href="#" onClick={goBack} state={location?.state} className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></a>
                    <span class="modal-level-title levels-title">{location?.state?.name}</span>
                </div>
            </div> 
            <div class="quiz-thumbnail">
                        <img src={location?.state?.image} alt="Quiz" />

                    </div>
             <div class="home-profile">
             <div class="row gx-1">           
                <div class="col-12">
                    <h3 class="section-title2 mb-3">{location?.state?.name} {t('15')}</h3>
                </div>
                <div class="col-12 quiz-info-meta-level">
                    <span class="quiz-info-meta-circle-img"><img src="/image/Group 19.png" alt="Quiz" /></span>
                    <span className='title-gap'>10 {t('16')}</span>
                </div> 
                  
                
            </div>
            <div class="quiz-info-content">
                <h4 class="quiz-info-content-title">{t('18')}</h4>
                <p>{location?.state?.description}</p>
            </div>
                  <h3 class="choose-title mb-3">{t('21')}</h3>
                  <div className='pb-2'>
                  
                    <div className={`row gx-0 beginner-tab d-flex align-items-baseline ${quizLevel.id == '1' ? 'active' : ''}`}  onClick={() => handleQuizClick(location?.state, levels[0])}>
                        <div class="col-7 text-start">
                            <div class="d-flex align-items-baseline">
                                <div class="avatar2"> 
                                    <img src="/image/Beginner.png" alt="Avatar" />
                                </div>
                                <div class="flex-grow-1 ms-2">
                                    <div class="home-profile-name">{t('22')}</div>
                                        {/* <div class="home-profile-status">Guest</div> */} 
                                </div>
                            </div>
                        </div>
                        <div class="col-5 text-end">
                            <div class="h-100 d-flex align-items-center justify-content-end">
                                <div class="home-profile-points"> 
                                    <span className='points-text'> {levels[0].points} {t('17')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                  </div>
                  <div className='pb-2'>
                  
                        <div className={`row gx-0 intermediate-tab d-flex align-items-baseline ${quizLevel.id == '2' ? 'active' : ''}`} onClick={() => handleQuizClick(location?.state, levels[1])}>
                            <div class="col-7 text-start">
                                <div class="d-flex align-items-baseline">
                                    <div class="avatar2"> 
                                        <img src="/image/Intermediate.png" alt="Avatar" />
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="home-profile-name">{t('23')}</div>
                                            {/* <div class="home-profile-status">Guest</div> */} 
                                        </div>
                                    </div>
                            </div>
                            <div class="col-5 text-end">
                                <div class="h-100 d-flex align-items-center justify-content-end">
                                    <div class="home-profile-points"> 
                                        <span className='points-text'> {levels[1].points} {t('17')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                  </div>
                  <div>
                    
                        <div className={`row gx-0 advance-tab d-flex align-items-baseline ${quizLevel.id == '3' ? 'active' : ''}`} onClick={() => handleQuizClick(location?.state, levels[2])}>
                            <div class="col-7 text-start">
                                <div class="d-flex align-items-baseline">
                                    <div class="avatar2"> 
                                        <img src="/image/Advance.png" alt="Avatar" />
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="home-profile-name">{t('24')}</div>
                                            {/* <div class="home-profile-status">Guest</div> */} 
                                        </div>
                                    </div>
                            </div>
                            <div class="col-5 text-end">
                                <div class="h-100 d-flex align-items-center justify-content-end">
                                    <div class="home-profile-points"> 
                                        <span className='points-text'> {levels[2].points} {t('17')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink
                            to="/finding"
                            className="btn btn-lg button-primary w-100"
                            state={{ qid: location?.state, lavel: quizLevel}}
                        >
                            {t('19')}
                        </NavLink>
                  </div>
                  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Screen3