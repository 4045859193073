import {React, useState, useEffect} from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { NavLink, useParams, useLocation, useNavigate   } from 'react-router-dom';
import { getOneCompetition, enterChampionship, getCompetitionInfo,getTopCompetition } from '../services/home';
import { useTranslation } from 'react-i18next';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      let timeLeft = {};
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
  
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearTimeout(timer);
    });
  
    const timerComponents = [];
  
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
  
      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]} {interval}{' '}
        </span>
      );
    });
  
    return (
      <div className='countdown-timer'>
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>Countdown complete!</span>
        )}
      </div>
    );
  };
  
const ChampionshipDetail = () => {
    const { t } = useTranslation();
    const [competition, setCompetition] = useState([]);
    const { id } = useParams();
    const [refresh, setRefresh] = useState(false);
    const [userCompetition, setUserCompetition] = useState([]);
    const [alreadyCompetition, setAlreadyCompetition] = useState(false);
    const [startTime, setStartTime] = useState();
    const [duration, setDuration] = useState();
    const [complete, setComplete] = useState(false);
    const [viewStatus, setViewStatus] = useState(false);
   
    const location = useLocation(); 
    const navigate = useNavigate();
    useEffect(() => {
      getOneCompetition(id).then((res) => {
        setCompetition(res.data)
        setTimeout(() => {
          setViewStatus(true);
        }, 1500);
        
      }).catch((err) => {
          console.log(err)
      })
     
      getCompetitionInfo(id).then((res) => {
        setUserCompetition(res.data)
        if(res.data   && res.data.rightScore != null) {
          setAlreadyCompetition(res.data)
        }
      }).catch((err) => {
          console.log(err)
      })
    }, [refresh])
    useEffect(() => {
      if (competition && competition.start_date_time) {
        const start_date_time =competition.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, "")
        setStartTime(new Date(start_date_time).getTime());
        setDuration(competition.duration * 1000)
      }
    }, [competition,duration]);

    const calculateTimeLeft = () => {
      if (competition && competition.start_date_time) {
      const start_date_time =competition.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, "")
      const difference = +new Date(start_date_time) - +new Date();
      let timeLeft = null;
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      return timeLeft;
      }
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearTimeout(timer);
    });

    useEffect(() => {
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        var startDate = new Date(startTime);
        var startTimeWithOffset = startDate.getTime() + (duration * 1000);
        const elapsedTime = currentTime - startTimeWithOffset;
        const remaining = Math.max(duration - elapsedTime, 0);
        if (remaining === 0) {
          setComplete(true); 
          clearInterval(interval);
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [startTime, duration]);
    
    const signUpCompetition = () => {
      enterChampionship({competition_id: id}).then(() => {
         setRefresh(true)
         if(!timeLeft) {
          StartCompetition();
         }
      }) 
    }
    const ViewResult = () => {
      navigate(`/championship/result/${id}`);
    }
    const StartCompetition = () => {
      navigate('/championship/play',{ state: competition });
    }
    return (
        <Layouts>
             
            <div className='home'>
                <div className="maine-div">
                    <div className="container box-wrapper">
                        <div class="header">
                            <div class="row align-items-center">
                                <div class="col-4 text-start">
                                    <div class="logo d-flex">
                                        <a href="#">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div class="col-8 text-end btnSearchBox">
                                   
                                </div>
                            </div>
                        </div>
                      
                        {competition ? (
                          
                            <div className='competition-details-page'>
                              <div className='row'>
                                <div className='col-md-12'>
                                  <div className='camp-img'>
                                    <img src="/image/soon.png" />
                                  </div>
                                </div>
                                <div className='col-md-12'>
                                  <div className='camp-content'>
                                    <div className='camp-title'>{competition.title} </div>
                                    {timeLeft ? (
                                    <div className='timer'>
                                      <img src="/image/chronometer.png" className='chronometer' />
                                      <CountdownTimer targetDate={new Date(competition.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, ""))} />
                                    </div>
                                    ):(
                                      <p className='competitionStart'>{t('74')}</p>
                                    )}
                                    <div className='competition-details-description'>{competition.description}</div>
                                  </div>
                                  {viewStatus ? (
                                  !userCompetition ? (

                                        !complete ? (                                         
                                          <button className='btn btn-lg button-primary w-100 start-competition' onClick={signUpCompetition}>{t('69')}</button>
                                          ) : (
                                            <button className='btn btn-lg button-primary w-100 start-competition' onClick={ViewResult} >View Result </button>  
                                          )

                                  
                                      ) : (
                                        !timeLeft ? (
                                          //alreadyCompetition
                                          alreadyCompetition ? (
                                            !complete ? (
                                            <p className='competition-complete'>{t('72')}</p>
                                            ) : (
                                             
                                              <button className='btn btn-lg button-primary w-100 start-competition' onClick={ViewResult} >View Result </button>  
                                            )
                                          ) : (
                                            !complete ? (
                                            
                                            <button className='btn btn-lg button-primary w-100 start-competition' onClick={StartCompetition} >{t('71')} </button>
                                            ) : (
                                              <button className='btn btn-lg button-primary w-100 start-competition' onClick={ViewResult} >View Result </button>  
                                            )
                                          )
                                          
                                        ) : (
                                          <p className='competition-already'>{t('73')}</p>
                                        )
                                  )
                                ): ( <></>) }
                                </div>
                              </div>
                            </div>
                         
                        ) : (
                          <div className='featured-quiz pb-2 text-center custom-campionship'>
                              <img src="/image/soon.png" alt="Logo" className='rocket' />
                              <h1 className='ft-65'>{t('67')}</h1>
                              <p className='cg-wine mt-4'>{t('68')}</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default ChampionshipDetail