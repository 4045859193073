import React, { useState, useEffect } from 'react'
import './css/style.css';
import Layouts from './layouts/layout';
import { NavLink, useNavigate  } from 'react-router-dom';
import { getProfile } from '../services/home';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    let navigate = useNavigate(); 

    const onLogout = () => {
        localStorage.clear();
        window.location.href = "/"
    }
    const [profile, setProfile] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getProfile();
          setProfile(res);
        } catch (err) {
          console.error(err);
        }
      };
  
      fetchData();
    }, []);

    const redirectToPage = (page) => {
        navigate(page);
    }
    const dummyImage = '/image/image 4.png';
  return (
    <Layouts>
    <div className='home'> 
      <div className="maine-div">
          <div className="container box-wrapper">
              <div class="header">
                  <div class="row align-items-center">
                      <div class="col-6 text-start">
                          <div class="logo d-flex">
                              <a href="#">
                                <img src="/image/Logo_new.png" alt="Logo" />
                              </a>
                              {/* <span className='logo-name'>CaliQuiz</span> */}
                          </div>
                      </div> 
                      {/* <div class="col-6 text-end">
                          <a href="#" class="search" id="btnSearch">
                          <img src="/image/search.png" alt="Logo" />
                          </a>
                      </div>  */}
                  </div>
              </div>
              <div class="home-profile">
                  <div class="row gx-0">
                      <div class="col-7 text-start">
                          <div class="d-flex align-items-center">
                              <div class="avatar"> 
                                <img src={profile?.image || dummyImage} alt="Avatar" className="Avatar" />
                              </div>
                              <div class="flex-grow-1 ms-2">
                                  <div class="profile-info"> 
                                  <h4 class="profile-name">{profile?.name} </h4>
                                  <p> {profile?.mobileNumber}</p>
                                  
                                  </div>
                                      {/* <div class="home-profile-status">Guest</div> */} 
                                  </div>
                              </div>
                      </div>
                      <div class="col-5 text-end">
                          <div class="h-100 d-flex align-items-center justify-content-end">
                              <span class="profile-subscriber"> {t('7')} </span>
                          </div>
                      </div>

                      <div class="col-12 text-start">
                         {/* <p class="profile-unsubscribe">{t('8')}</p> */}
                         <button className="pack-btn btn btn-primary btn-pm-custom"
                           onClick={() => {redirectToPage('/unsub')}}>{t("8")}</button>
                      </div>
                  </div>
              </div>
              
              <div class="content-box">
                 
              <ul class="profile-list-wrapper">
              <li class="profile-list" onClick={() => {redirectToPage('/history')}}>
                    <NavLink to="/history" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/controller.png" alt="History" class="game-icon" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('9')}</h4>
                        </div>
                    </NavLink>
                </li>
                <li class="profile-list" onClick={() => {redirectToPage('/edit')}}>
                    <NavLink to="/edit" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/Setting_group.png" alt="Edit Profile" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('10')}</h4>
                        </div>
                    </NavLink>
                </li>
                
                <li class="profile-list" onClick={() => {redirectToPage('/language')}}>
                    <NavLink to="/language" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/language.png" alt="Languages" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('11')}</h4>
                        </div>
                    </NavLink>
                </li> 
                <li class="profile-list" onClick={() => {redirectToPage('/policy')}}>
                      <NavLink to="/policy" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/note.png" alt="note" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('93')}</h4>
                        </div>
                    </NavLink>
                </li>
                <li class="profile-list" onClick={() => {redirectToPage('/terms')}}>
                      <NavLink to="/policy" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/note.png" alt="note" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('92')}</h4>
                        </div>
                    </NavLink>
                </li>

                <li class="profile-list" onClick={() => {redirectToPage('/faq')}}>
                      <NavLink to="/policy" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/note.png" alt="note" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('91')}</h4>
                        </div>
                    </NavLink>
                </li>

                <li class="profile-list" onClick={() => {redirectToPage('/contactus')}}>
                      <NavLink to="/contactus" className="d-flex w-100">
                        <div class="profile-list-pict">
                            <img src="/image/support.png" alt="support" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('13')}</h4>
                        </div>
                    </NavLink>
                </li>
                <li class="profile-list" onClick={onLogout}>
                      <NavLink to="#" className="d-flex w-100" >
                        <div class="profile-list-pict">
                            <img src="/image/logout.png" alt="logout" /> 
                        </div>
                        <div class="profile-list-info">
                            <h4 class="profile-list-info-title">{t('14')}</h4>
                        </div>
                    </NavLink>
                </li>
               </ul>                 
            </div>
          </div>
      </div> 
    </div>
    </Layouts>
  )
}

export default Profile