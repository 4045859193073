import React, { useEffect, useState } from 'react'
import './css/loss.css';
import BottomNav from './layouts/bottomNav';
import { getProfile } from '../services/home';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';

const LossGames = (props) => {
    const [statsVisible, setStatsVisible] = useState(false);
    const [profile, setProfile] = useState([]);  
    console.log("losssss--",props.state.lavel.perQuestion)
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getProfile();
          setProfile(res);
        //   console.log("update points",res)
        } catch (err) {
          console.error(err);
        }
      };
      if(props.isScoreCalculated){
        fetchData();
        playSound(props.userScore > props.opponentScore)
      }
    }, [props.isScoreCalculated]);

    const playSound = (answer) => {
        const audio = new Audio(`/sounds/${answer ? "winner" : "looser"}.mp3`); // Use the absolute path to the sound file
        audio.play();
      };

    const { t } = useTranslation();

    return (
        <>
        {props.userScore > props.opponentScore && <Confetti
        width={window.width}
        height={window.height}
        />}
        <div className='home'>
            <div className="maine-div">
                <div class="page-header">
                    <div class="box-wrapper mx-auto text-center position-relative">
                        {t('31')}
                    </div>
                </div>
                <div class="container box-wrapper loss-wrap">
                    <div class="text-center pb-4">
                        <div class="quiz-result-pict">
                            <div class="quiz-result-pict-ava "> 
                                
                                {props.userScore > props.opponentScore ? (
                                        <img src="/image/won.png" alt="Avatar"/>
                                    ) : (
                                        <img src="/image/loss.png" alt="Avatar"/>
                                    )}
                                </div>
                        </div>
                        <h4 class="quiz-result-status pt-5">{t('26')} {props.userScore > props.opponentScore ? `${t("61")}` : `${t("62")}`}!</h4> 
                        {props.userScore > props.opponentScore ? '' : <p class="quiz-result-ann pb-3">{t('32')}!</p>}                  
                        
                        <div class="quiz-result-points">
                            <div className='d-flex justify-content-between'>
                                <span class="quiz-result-points-badge">
                                    <img src="/image/Group 3.png" alt="Avatar"/>
                                </span>
                                <span className="pe-3">{profile?.totalPoints || 0} {t('17')}</span>
                            </div>
                        </div>
                    </div>

                    <div class="final-score" >
                        <div class="text-center final-card">
                            <div class="final-score-title">
                                <p class="m-0">{t('33')}</p>
                            </div>
                        </div>
                        <div class="final-score-top">
                            <div class="row">
                                <div class="col-6 text-center">
                                    <div class="final-score-top-box">{props.userScore * props.state.lavel.perQuestion}</div>
                                    <span class="mt-2 d-block final_name">{t('26')}</span>
                                    <div class="row gx-0 final-score-more">
                                        <div class="col-12 text-center"> 
                                            <p class="mb-0">
                                                <span className='right-text'>{t('34')}:</span> <span class="score-stats-badge">{props.userScore}</span>
                                                <span className='wrong-text'>{t('35')}:</span> <span class="score-stats-badge2">{10 - props.userScore}</span>
                                            </p>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="final-score-top-box2">{props.opponentScore * props.state.lavel.perQuestion}</div>
                                    <span class="mt-2 d-block final_name">Canon</span>
                                    <div class="row gx-0 final-score-more">
                                        <div class="col-12 text-center"> 
                                            <p class="mb-0">
                                               <span className='right-text'>{t('34')}:</span> <span class="score-stats-badge">{props.opponentScore}</span>
                                               <span className='wrong-text'>{t('35')}:</span> <span class="score-stats-badge2">{10 - props.opponentScore}</span>
                                            </p>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <BottomNav state={props.state.qid}/>
        </>
    )
}

export default LossGames