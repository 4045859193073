import React, { useState, useEffect } from 'react'
import './css/style.css';
import Layouts from './layouts/layout';
import { NavLink } from 'react-router-dom';
import { getAllQuiz, getProfile } from '../services/home';
import { useTranslation } from 'react-i18next';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tooltip } from 'react-tooltip'

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });
  

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <>
      {timerComponents.length ? (
        <><img src="/image/chronometer.png" className='chronometer' /> <div className='countdown-timer countdown-timer-home'> {timerComponents} </div> </>
      ) : (
        <div className='begin_the_competition'>Let's begin the competition!</div>
      )}
    </>
  );
};


const Screen2 = () => {
  const [featured, setFeatured] = useState([]);
  const [popular, setPopular] = useState([]);
  const [profile, setProfile] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));
  const { t, i18n } = useTranslation();
  const [randomText, setRandomText] = useState('');
  const [competition, setCompetition] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide:5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide:5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide:5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5,
      slidesToSlide:5
    }
  };

  const responsiveCompetition = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide:1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide:1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide:1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide:1
    }
  };


  useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getProfile();
          setProfile(res);
          console.log("update points",res)
        } catch (err) {
          console.error(err);
        }
      };
      getAllQuiz(localStorage.getItem('selectedLanguage')).then((res) => {
          const featuredArray = [];
          const popularArray = [];

          res.data.forEach((quiz) => {
            if (quiz.isFeatured) {
              featuredArray.push(quiz);
            } 
            if (quiz.isPopular) {
              popularArray.push(quiz);
            } 
          });
          setCompetition(res.competition)
          setFeatured(featuredArray);
          setPopular(popularArray);
        }).catch((err) => {
            console.log(err)
        })
      fetchData();
}, [])

useEffect(() =>{

  localStorage.setItem('selectedLanguage',language);
  i18n.changeLanguage(language);
  getAllQuiz(language).then((res) => {
      const featuredArray = [];
      const popularArray = [];

      res.data.forEach((quiz) => {
        if (quiz.isFeatured) {
          featuredArray.push(quiz);
        } 
        if (quiz.isPopular) {
          popularArray.push(quiz);
        } 
      });

      setFeatured(featuredArray);
      setPopular(popularArray);
    }).catch((err) => {
        console.log(err)
    })
},[language])

useEffect(() => {
     const totalFacts = localStorage.getItem("total_facts")
      const randomIndex = Math.floor(Math.random() * (totalFacts - 1 + 1)) + 1;
      setRandomText("fun_fact_"+randomIndex);
}, []); 

  return (
    <Layouts>
      <div className="home home-quiz">
        <div className="maine-div">
          <div className="container box-wrapper">
            <div class="header">
             
              <div class="row align-items-center">
                <div class="col-6 text-start">
                  <div class="logo d-flex">
                    <a href="#">
                      <img src="/image/Logo_new.png" alt="Logo" />
                    </a>
                    {/* <span className='logo-name'>CaliQuiz</span> */}
                  </div>
                </div>
                <div class="col-6 text-end">
                    <div className="pt-2">
                      <select
                      class="choose-option"
                      id="dropdown"
                      value={language}
                      onChange={(event) => setLanguage(event.target.value)}
                    >
                      <option class="choose-option" value="0">English</option>
                      <option value="4">Swahili</option>
                     {/* <option class="choose-option" value="0">English</option>
                      <option value="1">French</option>
                      <option value="2">Arabic</option>  
                      <option value="3">Portuguese</option> 
                      <option value="4">Swahili</option> */}
                    </select>
                    </div>
                </div>  
              </div>
            </div>
            <div class="home-profile">
              <div class="row gx-0">
                <div class="col-7 text-start">
                  <div class="d-flex align-items-center">
                    <div class="avatar">
                      {profile ? (
                        <img src={profile.image} alt="Avatar" />
                      ) : (
                        <img src="/image/image 4.png" alt="Avatar" />
                      )}
                    </div>
                    <div class="flex-grow-1 ms-2">
                      <div class="home-profile-name">{profile ? profile.name : "User"}</div>
                      <div class="home-profile-status">
                        {profile ? profile.mobileNumber : "Guest"}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-5 text-end">
                  <div class="h-100 d-flex align-items-center justify-content-end">
                    <div class="home-profile-points">
                      <span class="home-profile-points-badge">
                        {" "}
                        <img src="/image/crown.png" alt="Logo" />{" "}
                      </span>
                      <span className="points-text">
                        {profile?.totalPoints || 0} {t('17')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="competition-list pb-2">
             
              <div class="row gx-3">
              <Carousel responsive={responsiveCompetition} showDots={true} dotListClass="custom-dot-list-style" arrows={false} autoPlay={false} autoPlaySpeed={3000} infinite={true}>
                {competition.map((comp) => (
                  <div class="col-lg-12 col-md-12 col-12">
                    <NavLink to={`/championship/detail/${comp.id}`} >
                        <div className='row competition-image'>
                            <div className='col-md-3'>
                                <div className='competition-img'>
                                  <img src="/image/soon.png" />
                                </div>
                            </div>
                            <div className='col-md-9'>
                              <div className='competition-details'>
                                  <div className='title'> {comp.title}</div>
                                  <div className='description'>{comp.description}</div>
                                  <div className='timer'> <CountdownTimer targetDate={new Date(comp.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, ""))} /> </div>
                              </div>
                            </div>
                        </div>
                    </NavLink>
                  </div>
                ))}
                </Carousel>
              </div>
            </div>
            

            <div class="featured-quiz pb-2 custom-fact-fun mt-3">


            <div className="did-you-know">
              <img src="/image/fun-fact.png" alt="Bulb icon" className="icon" />
              <div>
                <p className="description">{t(randomText)}</p>
              </div>
            </div>

              
            </div>

            <div class="featured-quiz pb-2">
              <h3 class="section-title mb-3">{t('2')}</h3>
              <div class="row gx-3">
              <Carousel responsive={responsive} showDots={true} dotListClass="custom-dot-list-style" arrows={false} autoPlay={true} autoPlaySpeed={5000} infinite={true}>
              <Tooltip id="my-tooltip-2" />
                {popular.map((quiz) => (
                  <div class="col-lg-2 col-md-3 col-3">
                    <NavLink to="/levels" state={quiz}>
                      <a class="quiz-box">
                        {quiz?.image ? (
                          <img src={quiz?.image} />
                        ) : (
                          <img src="image/science.png" alt="Quiz" />
                        )}
                        <p data-tooltip-id="my-tooltip-2"
  data-tooltip-content={quiz.name}
  data-tooltip-place="bottom" class="quiz-box-title">{quiz.name.length >= 9 ? quiz.name.substring(0, 6)+'...' : quiz.name}</p>
                      </a>
                    </NavLink>
                  </div>
                ))}
                </Carousel>
              </div>
            </div>
            <div class="featured-quiz">
              <h3 class="section-title mb-3 ">{t('3')}</h3>
              <div class="row gx-3">
              <Tooltip id="my-tooltip" />
                {featured.map((quiz) => (
                  <div class="col-lg-2 col-md-3 col-3">
                    <NavLink to="/levels" state={quiz}>
                      <a class="quiz-box">
                        {quiz?.image ? (
                          <img src={quiz?.image} />
                        ) : (
                          <img src="image/science.png" alt="Quiz" />
                        )}
                        <p  data-tooltip-id="my-tooltip"
  data-tooltip-content={quiz.name}
  data-tooltip-place="bottom" class="quiz-box-title">{quiz.name.length >= 9 ? quiz.name.substring(0, 6)+'...' : quiz.name}</p>
                      </a>
                    </NavLink>
                    
                  </div>
                ))}
                
              </div>
              <NavLink
                to="/moregames"
                className="btn btn-lg button-primary w-100"
                
              >
                {t('4')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default Screen2