import React from 'react';
import { Navigate, Route, Routes} from "react-router-dom";
import Screen2 from '../components/screen2'
import Screen3 from '../components/screen3'
import AllQuizes from '../components/allQuizes'
import GameDetails from '../components/gameDetails'
import LeaderBoard from '../components/leaderBoard'
import FindingOpponent from '../components/findingOpponent'
import Terms from '../components/terms'
import Policy from '../components/policy'
import Login from '../components/login/login'
import Register from '../components/login/register'

const OpenRoute = () => {
    return (
        <Routes>
                <Route exact path='/' element={<Register/>} /> 
                <Route path='/home' element={<Screen2/>}  />
                <Route path='/levels' element={<Screen3/>}  />
                <Route path='/moregames' element={<AllQuizes/>}  />
                <Route path='/gamedetails' element={<GameDetails/>}  />
                <Route path='/leaderboard' element={<LeaderBoard/>}  />
                <Route path='/finding' element={<FindingOpponent/>}  />
                <Route path='/login' element={<Login/>}  />
                <Route path='/register' element={<Register/>}  />
                <Route path='/terms' element={<Terms/>}  />
                <Route path='/policy' element={<Policy/>}  />
                <Route path='*' element={<Navigate to="/register" />} />

        </Routes>
    )
};

export default OpenRoute;