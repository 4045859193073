import React, { useState } from 'react'
import './css/loss.css';
import BottomNav from './layouts/bottomNav';
import { useTranslation } from 'react-i18next';
const WinGames = () => {
    const [statsVisible, setStatsVisible] = useState(false);
    const { t } = useTranslation();
    const toggleStatsVisibility = () => {
      setStatsVisible(!statsVisible);
    };
    return (
        <>
        <div className='home'>
            <div className="maine-div">
                <div class="page-header">
                    <div class="box-wrapper mx-auto text-center position-relative">
                        {t('31')}
                    </div>
                </div>
                <div class="container box-wrapper">
                    <div class="text-center pb-5">
                        <div class="quiz-result-pict">
                            <div class="quiz-result-pict-ava "> 
                                <img src="/image/won.png" alt="Avatar"/>
                            </div>
                        </div>
                        <h4 class="quiz-result-status pt-5 pb-5">{t('57')}!</h4> 
  
                        <div class="quiz-result-points">
                            <div className='d-flex justify-content-between'>
                                <span class="quiz-result-points-badge">
                                    <img src="/image/Group 3.png" alt="Avatar"/>
                                </span>
                                <spna className="pe-1">21020 {t('17')}</spna>
                            </div>
                        </div>
                    </div>

                    <div class="final-score" >
                        <div class="text-center final-card">
                            <div class="final-score-title">
                                <p class="m-0">{t('33')}</p>
                            </div>
                        </div>
                        <div class="final-score-top">
                            <div class="row">
                                <div class="col-6 text-center">
                                    <div class="final-score-top-box">10</div>
                                    <span class="mt-2 d-block final_name">Canon</span>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="final-score-top-box2">0</div>
                                    <span class="mt-2 d-block final_name">{t('26')}</span>
                                </div>
                            </div>
                        </div>
                        <div class="final-score-detail" id="btnStats">
                            <h4 class="final-score-detail-title"  onClick={toggleStatsVisibility}>{t('60')} <span> <img src="/image/arrow_drop_down.png" alt="Avatar"/></span></h4>
                            <ul class="final-score-more" style={{ display: statsVisible ? "block" : "none" }}>
                                <li>
                                    <div class="row gx-0">
                                        <div class="col-8 text-start">
                                            <p class="mb-1 fw-bold">Canon</p>
                                            <p class="mb-0">
                                               <span className='right-text'>{t('34')}:</span> <span class="score-stats-badge">1</span>
                                               <span className='wrong-text'>{t('35')}:</span> <span class="score-stats-badge2">0</span>
                                            </p>
                                        </div>
                                        <div class="col-4 text-end">
                                            <p class="mb-1 p_color">1.14</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row gx-0">
                                        <div class="col-8 text-start">
                                            <p class="mb-1 fw-bold">1234567890</p>
                                            <p class="mb-0">
                                                <span className='right-text'>{t('34')}:</span> <span class="score-stats-badge">0</span>
                                                <span className='wrong-text'>{t('35')}:</span> <span class="score-stats-badge2">68</span>
                                            </p>
                                        </div>
                                        <div class="col-4 text-end">
                                            <p class="mb-1 p_color">0</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomNav />
        </>
    )
}

export default WinGames