import axios from 'axios';
import baseURL from '../utils/baseUrl';

const instance = axios.create({
    baseURL
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
           localStorage.clear();
           window.location.href = '/';
           return;
        }
        return Promise.reject(error);
    });

export default instance;