import React, { useEffect, useState } from 'react'
import './css/screen3.css';
import { NavLink, useLocation } from 'react-router-dom';
import { getAllOpponent } from '../services/home';
import { useTranslation } from 'react-i18next';

const FindingOpponent = () => {
    const [opponent, setOpponent] = useState();
    const location = useLocation(); 
    const { t } = useTranslation();
    // console.log("location---Opponent",location.state)
    useEffect(() => {
         getAllOpponent().then((res) => {
            // console.log(res)
            setOpponent(res)
            location.state.players = res
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper difficult">
                    <div class="page-header pb-5">
                        <div class="box-wrapper mx-auto text-center position-relative">
                            <NavLink to="/levels" className="page-header-back" state={location.state.qid}><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                            <span class="modal-level-title levels-title">{t('25')}</span>
                        </div>
                    </div>
                    <div class="card-simple">
                        <div className='card-row'>
                            <div class="row ">
                                <div class="col-4">
                                    <div class="opponent">
                                        <div class="opponent-avatar"> 
                                            <img src={opponent?.user?.image ? opponent?.user?.image : "/image/image 4.png"} alt="Avatar" />
                                        </div>
                                        <p class="m-0 name-title">{t('26')}</p>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="opponent2">
                                        <div class="opponent-avatar2">
                                            <img src="/image/opponent.png" alt="Avatar"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4"> 
                                    <div class="opponent" id="opponentFinding" style={{display: "none"}}>
                                        <div class="opponent-avatar">
                                            <div class="opponent-avatar-loading">
                                                <img src="https://demo.quizpro.mobi//theme/images/finding.gif" alt="Loading"/>
                                            </div>
                                        </div>
                                        <p class="m-0 text-muted">{t('27')}...</p>
                                    </div> 
                                    <div class="opponent" id="opponentFound">
                                        <div class="opponent-avatar">
                                            <img src={opponent?.opponent?.image ? opponent?.opponent?.image : "/image/image 4.png"} alt="Avatar"/>
                                        </div>
                                        <p class="m-0 name-title">{opponent?.opponent?.name}</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        
                        <p class="my-3 text-center opponentMsg" id="opponentMsg" style={{visibility: "visible"}}>{t('28')}!</p>
                        <NavLink to="/play" className="btn btn-lg button-primary w-100" id="btnGamePlay" state={location?.state}>{t('29')}</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindingOpponent