import React, {useState,useEffect} from 'react'
import './css/screen3.css';  
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));

  useEffect(() =>{
    console.log("Value of language *******####### ", language);
    localStorage.setItem('selectedLanguage',language);
    i18n.changeLanguage(language);
  },[language])  
  return ( 
    <div className='home'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('11')}</span>
                </div>
            </div> 
             <div class="home-profile">
                  <h3 class="choose-title mb-3">{t('43')}:</h3>

                <form class="languageForm">
                    <div class="choose-option language-between" for="forone">
                        <label >English</label>
                        <input id="forone" type="radio" name="r" value="0" checked={language === "0"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    <div class="choose-option language-between" for="forfour">
                        <label >Swahili</label>
                        <input id="forfour" type="radio" name="r" value="4" checked={language === "4"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    {/*
                    <div class="choose-option language-between" for="forthree">
                        <label >French</label>
                        <input id="forthree" type="radio" name="r" value="1"  checked={language === "1"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    
                    <div class="choose-option language-between" for="fortwo">
                        <label >Arabic</label>
                        <input id="fortwo" type="radio" name="r" value="2" checked={language === "2"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    <div class="choose-option language-between" for="forfour">
                        <label >Portuguese</label>
                        <input id="forfour" type="radio" name="r" value="3" checked={language === "3"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    <div class="choose-option language-between" for="forfour">
                        <label >Swahili</label>
                        <input id="forfour" type="radio" name="r" value="4" checked={language === "4"}  onClick={(event) => setLanguage(event.target.value)} />
                    </div>
                    */}

                    
                </form>
                  
                  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Language