import React, { useState, useEffect } from 'react'
import './css/screen3.css';
import { NavLink, useParams, useLocation   } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const GameDetails = (props) => { 
    const location = useLocation();
    const { t } = useTranslation();
    console.log(location?.state) 
    return (
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper difficult">

                    <div class="page-header pb-4">
                        <div class="box-wrapper mx-auto text-center position-relative">
                            <NavLink to="/moregames" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                            <span class="modal-level-title game-title">{location?.state?.name}</span>
                        </div>
                    </div>
                    <div class="quiz-thumbnail">
                        <img src={location?.state?.image} alt="Quiz" />

                    </div>
                    <div class="quiz-info">
                       
                        <div class="">
                            <div class="row quiz-info-row gx-1">
                                
                                <div class="col-6">
                                <h3 class="section-title2 mb-3">{location?.state?.name} {t('15')}</h3>
                                </div>
                                <div class="col-6 quiz-info-meta2">
                                    <span class="quiz-info-meta-circle-img"><img src="/image/Group 19.png" alt="Quiz" /></span>
                                    <span className='title-gap'>10 {t('16')}</span>
                                </div>   
                                {/* <div class="col-6 quiz-info-meta2">
                                    <span class="quiz-info-meta-circle-img"><img src="/image/Group 3.png" alt="Quiz" /></span>
                                    <span className='title-gap'>100 Points</span>
                                </div> */}
                            </div>
                        </div>
                        <div class="quiz-info-content">
                            <h4 class="quiz-info-content-title">{t('18')}</h4>
                            <p>{location?.state?.description}</p>
                        </div>

                        <NavLink to='/levels' className="btn btn-lg button-primary w-100" state={location?.state}>{t('19')}</NavLink>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameDetails