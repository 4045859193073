import React, { useState, useEffect } from 'react'
import './css/screen3.css';  
import { NavLink } from 'react-router-dom';
import { getProfile,updateProfile } from '../services/home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import {Loader} from "../utils/loader"

const EditProfile = () => {

    const [loading, setLoading] = useState(false); 
    const [profile, setProfile] = useState([]); 
    const [state, setState] = useState({name: "",image: "",});

    const fetchData = async () => {       
        try {
                const res = await getProfile();
                //console.log(res); 
                setProfile(res);
                setState(prevState => ({
                    ...prevState,
                    name: res.name,
                }))
        } catch (err) {
          console.error(err);
        }
      };

    useEffect(() => { 
      fetchData();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
           // console.log(file);
            const reader = new FileReader();
    
            reader.onloadend = () => {
                setState(prevState => ({ ...prevState, image: reader.result }));
                profile.image = reader.result
            };
    
            reader.readAsDataURL(file);
        }
    };
    const handleFileRemove = () => {
        setState(prevState => ({ ...prevState, image: '' }));
        profile.image = '' 
    }



    const onSubmit = async (event) => {
        event.preventDefault();  
        setLoading(true);
        await updateProfile({
            ...state
        }).then(res => {
            toast("Profile update has been completed successfully.")
            //fetchData();
            setProfile(res);
            setState(prevState => ({...prevState,name: res.name,}))
        }).catch(err => {
            toast("An error occurred; please try again later.")
            console.log(err)
        }).finally(() => setLoading(false));
        
    }

    //console.log(profile)
    const dummyImage = '/image/image 4.png';
    const { t } = useTranslation();


  return ( 
    <><ToastContainer /><div className='home'>
          <div className="maine-div">
              <div className="container-fluid box-wrapper difficult">
                  <div className="page-header pb-5">
                      <div className="box-wrapper mx-auto text-center position-relative">
                          <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                          <span className="modal-level-title levels-title">{t('10')}</span>
                      </div>
                  </div>
                  <div className="edit-profile">
                      <div className='pb-2'>
                          <div className="row gx-0  d-flex">
                              <div className="col-3 text-start">
                                  <div className="d-flex align-items-baseline">
                                      <div className="user-avatar">
                                          <img src={profile?.image || dummyImage} alt="Avatar" className="profile" />
                                          <span className="delete-avatar"><img src="/image/delete.png" alt="Avatar" className="delete" onClick={handleFileRemove} /></span>
                                      </div>

                                  </div>

                              </div>

                              <div className="col-9 text-start avatar-title">
                                  <div className="flex-grow-1 ms-2">
                                      <div className="edit-profile-name">Avatar</div>
                                      <div className="choose-file-wrapper">
                                          <button className="choose-file"><img src="/image/cloud_upload.png" alt="Avatar" className="cloud_upload" /> {t('40')}</button>
                                          <input type="file" className="myChoosefile" name="file" onChange={handleFileChange} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 text-start profile-setting">
                              <hr />
                              <div className="form-group">
                                  <label for="exampleInputEmail1">{t('41')}</label>
                                  <input type="email" className="form-control" placeholder="Enter Name" value={state.name} onChange={(event) => setState({ ...state, name: event.target.value })} />
                              </div>

                              {loading ? <Loader />:<button type="submit" className="btn btn-primary" onClick={onSubmit}>{t('42')}</button>}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div></> 
  )
}

export default EditProfile