
import React, { useState } from "react";
import './css/screen3.css'; 
import './css/faq.css'; 
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Terms = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage'));
  return ( 
    <div className='home'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('92')}</span>
                </div>
            </div> 
             <div class="home-profile">
              {language==4?(<>
                <div>
                <p><strong>1. Ufafanuzi.</strong></p>
                <p>Quiz Master ni huduma ya kufurahisha na kuvutia wateja wa Vodacom kupata kujibu maswali ya mada mbalimbali na kujishindia zawadi za kila mwezi. Hii ni huduma inayotegemea usajili ambapo wateja watalazimika kujisajili na kulipia ada ya huduma kulingana na mpango wa usajili wanaochagua.&nbsp;</p>
                
                <p><strong>2. Kutumika</strong></p>
                <ol>
                <li>Sheria na Masharti haya baadaye (" <strong>Sheria na Masharti/Masharti ya Matumizi </strong>") kama yatakavyotumika kwa kubadilishana) yametolewa na Vodacom Tanzania PLC (baadaye &ldquo; <strong>Vodacom </strong>&rdquo; au &ldquo; <strong>sisi </strong>&rdquo; au &ldquo; <strong>sisi </strong>&rdquo; &ldquo; <strong>wenyewe </strong>&rdquo; au &ldquo; <strong>yetu </strong>&rdquo;) na inatumika kwa Wateja/Wateja wa Vodacom (&ldquo; <strong>Mteja </strong>&rdquo;) (baadaye &ldquo; <strong>wewe </strong>&rdquo; au &ldquo; <strong>wako </strong>&rdquo; au &ldquo; <strong>mtumiaji </strong>&rdquo; &ldquo; <strong>wewe mwenyewe </strong>&rdquo;) wanaotumia Huduma.</li>
                <li>Tafadhali soma Sheria na Masharti haya, na Sheria na Masharti ya Msajili Mkuu wa Vodacom ambayo yatakuwa sehemu ya Sheria na Masharti haya yaliyopatikana kutoka kwa tovuti ya Vodacom.&nbsp;</li>
                </ol>

                <p><strong>3. Gharama na Aina ya Huduma</strong></p>
                <ol>
                  <li><u>Malipo </u>:</li>
                  <p>Wateja wanaweza kujiandikisha kwa Quiz Master na kulipa gharama zifuatazo. Gharama hizi zitasasishwa kiotomatiki na zitakoma tu wakati mteja atajiondoa kutoka kwa huduma.</p>
                  <ul>
                  <li><em>Kila siku - Tsh 200</em></li>
                  <li><em>Kila wiki - Tsh 500</em></li>
                  <li><em>Kila mwezi - Tsh 800</em></li>
                  </ul>
                </ol>
                <ol start="2">
                <li><u>Tabia ya huduma.</u></li>
                <p>Hii ni huduma inayotegemea usajili ambayo inaweza kufikiwa na wateja wa simu mahiri na wanaohusika. Wateja ambao watatumia huduma kupitia tovuti ya tovuti watatozwa ada za data. Wateja pia watakuwa na nafasi ya kujishindia zawadi mbalimbali za pesa taslimu kila mwezi ikiwa wamepata alama za juu zaidi. Ufuatao ni ugawaji wa zawadi za fedha taslimu.</p>
                <ul>
                <li><sup>wa </sup>1 atapokea Tsh 100,000 kwenye pochi yake ya M-Pesa.</li>
                <li><sup>Mshindi wa </sup>2 hadi wa 5 kila mmoja atapata Tsh 50,000 kwenye pochi yake ya M-Pesa.</li>
                <li><sup>Mshindi wa </sup>6 hadi 10 kila mmoja atapata Tsh 20,000 kwenye pochi yake ya M-Pesa.</li>
                </ul>
                </ol>
                
                <p><strong>4. UPATIKANAJI NA UPATIKANAJI WA HABARI</strong></p>
                <p>Huduma hii inapatikana 24/7 unapofanya malipo kupitia USSD (*149*67#) na/au kupitia tovuti ya tovuti <a href="https://vtz.quizzzmaster.com/">https://vtz.quizzzmaster.com/</a>&nbsp;</p>
               
                <p><strong>5. CHAGUA -KUINGIA NA KUTOKA MITAMBO</strong></p>
                <p>Wateja wanaweza kujiandikisha kwa huduma kwa kufuata mojawapo ya hatua hizi.</p>
                <ol>
                  <li><strong><u>Kupitia Menyu ya USSD</u></strong></li>                
                  <ul>
                  <li>Mteja piga *149*67#</li>
                  <li>Chagua SUBSCRIBE</li>
                  <li>Fuata maagizo yanayofuata.&nbsp;</li>
                  </ul>
                </ol>

                <ol start="2">
                  <li><strong><u>Kupitia Vodacom App yangu</u></strong></li>
                  <ul>
                  <li>Wateja wafungua programu yangu ya Vodacom.</li>
                  <li>Chagua Huduma za Dijiti kisha Trivia kisha Quiz Master.</li>
                  </ul>                
                </ol>

                <ol start="3">
                  <li><strong><u>Kupitia Tovuti ya Wavuti</u></strong></li>                
                  <ul> 
                  <li>Mteja atafungua https://vtz.quizzzmaster.com/.</li>
                  <li>Weka nambari zao za simu.</li>
                  <li>Kisha fuata maagizo yanayofuata.</li>
                  </ul>
                  <p>Wateja wanaweza pia kujiondoa kwenye huduma kwa kufuata hatua zifuatazo.</p>
                </ol>               

                <ol start="4">
                  <li><strong><u>Kupitia menyu ya iCG</u></strong></li>
                  <ul>
                  <li>Mteja atapiga *149*83# kisha kuchagua mpango anaotaka kujiondoa.</li>
                  </ul>
                </ol>               

                <ol start="5">
                  <li><strong><u>Kupitia Menyu ya USSD</u></strong></li>
                  <ul>
                  <li>Mteja atapiga *149*67# kisha uchague JIONDOE.</li>
                  </ul>
                </ol>
                

                <p><strong>6. Sheria na Masharti Mengine Yanayotumika</strong></p>
                <ul>
                  <li>Sheria na Masharti haya yatasomwa pamoja na Sheria na Masharti ya Msajili Mkuu wa Vodacom.</li>
                  <li>Katika tukio la kutofautiana, kupishana au kupingana kati ya sheria na masharti haya na Sheria na Masharti ya Msajili Mkuu wa Vodacom, utaratibu wa utangulizi na utumikaji utakuwa ni Sheria na Masharti haya Kanuni na Masharti ya Msajili Mkuu wa Vodacom.</li>
                  <li>Mteja na au Mteja yeyote anayetumia Huduma zinazotolewa chini ya Sheria na Masharti haya atachukuliwa kuwa amesoma na kuelewa Sheria na Masharti.</li>
                  <li>Tuna haki ya kusimamisha Huduma:
                  <ul>
                  <li>Ikiwa Unatumia Huduma kwa madhumuni yoyote ambayo hayajaidhinishwa.</li>
                  <li>Tukigundua matumizi mabaya/matumizi mabaya yoyote, ukiukaji wa maudhui, ulaghai au jaribio la ulaghai linalohusiana na matumizi yako ya Huduma.</li>
                  <li>Iwapo tutahitajika au kuombwa kufanya hivyo ili kutii amri au maagizo ya au pendekezo kutoka kwa serikali, mahakama, mdhibiti, au mamlaka nyingine husika.</li>
                  <li>Ikiwa tunashuku au kuamini kuwa umekiuka Masharti haya ya Matumizi.</li>
                  <li>Ikihitajika kufanya hivyo ili kushughulikia matatizo ya kiufundi au kwa sababu za kuhakikisha usalama baada ya taarifa inayofaa kwako.</li>
                  <li>Ili kuwezesha kusasisha au kuboresha maudhui au utendaji wa Huduma mara kwa mara baada ya ilani inayofaa kwako.</li>
                  <li>Ikiwa tutaamua kusimamisha au kusitisha utoaji wa Huduma kwa sababu za kibiashara au kwa sababu nyingine yoyote kama tunavyoweza kuamua.</li>
                  </ul>
                  </li>
                </ul>
                
                <p><strong>7. Mabadiliko ya Sheria na Masharti Haya</strong></p>
                <ul>
                <li>Tunaweza kuongeza au kubadilisha Masharti haya ya Matumizi kwa mujibu wa kifungu cha 7.2. Tunaweza kuongeza ada na ada mpya au kubadilisha ada na ada zilizopo wakati wowote; kama matokeo ya moja kwa moja ya sheria mpya, hati ya kisheria, kanuni au leseni za Serikali, viwango vya ubadilishaji, uwekaji, au mabadiliko ya kodi ya serikali au kama matokeo ya mapitio yoyote ya mipango ya biashara ya Vodacom, mabadiliko ndani ya tasnia, mapendekezo kutoka kwa mashirika ya udhibiti au sababu nyingine kama inaweza kuamua.</li>
                <li>Tutakujulisha mapema ikiwa tutaongeza au kubadilisha sheria na masharti haya au ikiwa tutaongeza ada na ada mpya au kubadilisha zilizopo wakati au baada ya usajili wa Huduma. Kiasi na aina ya ilani tutakayokupa itafuata mkondo unaohitajika unaotumika na unaopatikana wakati huo. (Kwa mfano, tunaweza kukuarifu kwa barua, barua pepe, simu (pamoja na ujumbe uliorekodiwa au SMS) au kwa tangazo katika gazeti la serikali la kila siku au la wiki au katika tovuti yetu au njia nyingine yoyote), unashauriwa pia kutembelea tovuti mara kwa mara ili kuona mabadiliko iwapo kutokana na baadhi ya sababu zisizotarajiwa huenda usipokee arifa ya masasisho.</li>
                <li>Tukibadilisha au kuongeza kwa Sheria na Masharti haya na hufurahii mabadiliko hayo, unaweza kufuta Sheria na Masharti haya kwa mujibu wa Kifungu cha 8 hapa chini. Iwapo hutatuomba tusitishe Masharti haya ya Matumizi na bado unatumia Huduma, unachukuliwa kukubali mabadiliko katika tarehe ya kuanza kutumika.</li>
                </ul>
                
                <p><strong>8. Kukomesha Huduma na au Masharti ya Matumizi</strong></p>
                <p>Tunaweza kusitisha Masharti haya ya Matumizi wakati wowote kwa hiari yetu. Usitishaji kama huo utategemea kukujulisha. Arifa inaweza kuwa kwa barua, barua pepe, simu (pamoja na ujumbe uliorekodiwa au SMS) au kwa tangazo katika gazeti la serikali la kila siku au la wiki au katika tovuti yetu au njia nyingine yoyote. Unashauriwa pia kutembelea tovuti yetu mara kwa mara ili kuona mabadiliko iwapo kutokana na sababu zisizotarajiwa huenda usipate arifa ya sasisho.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>9. Mkuu</strong></p>
                <ul>
                  <li>Hatutawajibika kwa ucheleweshaji wowote au kutofaulu katika kutekeleza majukumu yetu yoyote kuhusiana na matumizi ya Huduma ambapo ucheleweshaji kama huo au kutofaulu kunatokea kwa sababu ya hali yoyote iliyo nje ya udhibiti mzuri wa Huduma yetu.</li>
                  <li>Huenda usibadilishe Masharti haya ya Matumizi na huwezi kuhamisha masharti haya kwa mtu mwingine au biashara yoyote.</li>
                  <li>Masharti haya ya Matumizi yatasimamiwa na kutafsiriwa kwa mujibu wa sheria ya Tanzania.</li>
                  <li>Hatutawajibika kwa hasara yoyote isiyo ya moja kwa moja na au ya Matokeo.</li>
                  <li>Mawasiliano yote chini ya Kanuni na Masharti haya yatakuwa katika Kiingereza au Kiswahili.</li>
                  <li>Huduma kwa Wateja
                    <ul>
                    <li>Unaweza kuwasiliana nasi kupitia nambari 100 za kituo chetu cha huduma kwa wateja au mawasiliano mengine ya huduma kwa wateja yaliyotolewa kwenye mitandao ya kijamii au Tovuti (yaani, TOBi Online au Tuma ombi) ili kuripoti mizozo, madai au hitilafu zozote katika Huduma.</li>
                    <li>Wawakilishi wetu wa huduma kwa wateja watashughulikia kesi/kesi zilizoripotiwa kwa mujibu wa taratibu zetu za kawaida za kushughulikia malalamiko zinazopatikana katika maduka yetu ya reja reja na kwenye tovuti yetu.</li>
                    <li>Tunaweza kurekodi au kufuatilia simu kwa Kituo chetu cha Huduma kwa Wateja au sehemu yoyote ya kukugusa kwa madhumuni ya mafunzo, ili kuwezesha maagizo kuthibitishwa na kutathmini kama viwango vyetu vya huduma vinatimizwa.</li>
                    </ul>
                  </li>
                  <li>Tunaweza kuhamisha kwa mtu mwingine yeyote haki zake zote au zote na/au wajibu chini ya Masharti haya ya Matumizi kulingana na kukuarifu. Licha ya hayo yaliyotangulia, haki zako za kisheria hazitaathiriwa, na majukumu yako hayataongezwa kutokana na hilo. Huwezi kuhamisha haki na wajibu wako chini ya Masharti haya ya Matumizi.</li>
                </ul>

                <p><strong>10. Mawasiliano na Malalamiko</strong></p>
                <ul>
                <li>Ikiwa ungependa kuuliza maelezo zaidi piga simu kupitia nambari yetu ya simu ya huduma kwa wateja kama ilivyoelezwa hapa juu</li>
                <li>Ikiwa haujaridhika na kipengele chochote cha huduma inayotolewa, tafadhali wasiliana na Huduma kwa Wateja. Watafurahi kusaidia na kuelezea utaratibu wa malalamiko kwa undani zaidi. Tutajaribu na kutatua malalamiko yako haraka iwezekanavyo. Walakini, mara nyingi malalamiko yatahitaji kuchunguzwa. Ikiwa hali ndiyo hii, tutakujibu ndani ya Siku 7 za Biashara ili kukubali kupokea malalamiko yako na tunaweza kukujibu muda ambao utachukua kabla ya malalamiko yako kutatuliwa. Ikiwa malalamiko yako hayawezi kutatuliwa, utashauriwa ipasavyo.</li>
                </ul>
                </div>
              </>):(<>

                <div>
                <p><strong>1. Definitions</strong></p>
                <p>Quiz Master is a fun and engaging trivia service where Vodacom customers get to answer questions from various topics and win monthly prizes. This is a subscription-based service where customers will have to subscribe and pay for the service fee depending on the subscription plan they choose.</p>
                <p><strong>2. Applicability</strong></p>
                <ol>
                <li>These Terms and Conditions hereinafter ("<strong>Terms and Conditions/Conditions of Use</strong>") as shall be used interchangeably) are issued by Vodacom Tanzania PLC (hereinafter &ldquo;<strong>Vodacom</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo; &ldquo;<strong>ourselves</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo;) and are applicable to Vodacom Subscribers/Customers (&ldquo;<strong>Customer</strong>&rdquo;) (hereinafter &ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>your</strong>&rdquo; or &ldquo;<strong>user</strong>&rdquo; &ldquo;<strong>yourself</strong>&rdquo;) who uses the Services.</li>
                <li>Please read these Terms and Conditions, and Vodacom General Subscriber Terms and Conditions that shall form part of these Terms and Conditions obtained from Vodacom website.</li>
                </ol>
                <p><strong>3. Charges and Nature of Services</strong></p>                
                <ol>
                <li><u>Charges</u>:</li>
                <p>Customers can subscribe to Quiz Master and pay the following charges. These charges will renew automatically and only stop when the customer unsubscribes from the service.</p>
           
                </ol>
                 <ul>
                <li><em>Daily &ndash; Tsh 200</em></li>
                <li><em>Weekly &ndash; Tsh 500</em></li>
                <li><em>Monthly &ndash; Tsh 800</em></li>               
                </ul>
                <ol start="2">
                <li><u>Nature of the service.</u></li>
                <p>This is a subscription-based service that is accessible to both smartphone and feature phone customers. Customers who will use the service via web portal will incur data charges. Customers will also have a chance to win various cash prizes monthly if they have scored the highest points. The following is the distribution of the cash prizes.</p>
           
                </ol>
                   <ul>
                <li>1<sup>st</sup> Winner will receive Tsh 100,000 in their M-Pesa wallet.</li>
                <li>2<sup>nd</sup> to 5<sup>th</sup> winner will each receive Tsh 50,000 in their M-Pesa wallet.</li>
                <li>6<sup>th</sup> to 10<sup>th</sup> winner will each receive Tsh 20,000 in their M-Pesa wallet.&nbsp;</li>
                </ul>
                <p><strong>4. AVAILABILITY AND ACCESSIBILITY OF INFORMATION</strong></p>
                <p>This service is available 24/7 upon successful payment through USSD (*149*67#) and/or via web portal <a href="https://vtz.quizzzmaster.com/">https://vtz.quizzzmaster.com/</a></p>
              
                <p><strong>5. OPT &ndash;IN AND OUT MECHANISM</strong></p>
                <p>Customers can subscribe to the services by following either of these steps.</p>

                <ol start="1">
                <li><strong><u>Via USSD Menu</u></strong></li>
                </ol>
                <ul>
                <li>Customer dial *149*67#</li>
                <li>Select SUBSCRIBE</li>
                <li>Follow the next instructions.</li>
                </ul>

                <ol start="2">
                <li><strong><u>Via My Vodacom App</u></strong></li>
                </ol>
                <ul>
                <li>Customers open My Vodacom app.</li>
                <li>Select Digital Services then Trivia then Quiz Master.</li>
                </ul>

                <ol start="3">
                <li><strong><u>Via Web Portal</u></strong></li>
                </ol>
                <ul>
                <li>Customer will open <a className="link" href="https://vtz.quizzzmaster.com">https://vtz.quizzzmaster.com</a></li>
                <li>Enter their mobile number.</li>
                <li>Then follow the next instructions.</li>
                </ul>
                <p>Customers can also opt out of the service by following the following steps.</p>
                <p>&nbsp;</p>

                <ol start="4">
                <li><strong><u>Via iCG menu</u></strong></li>
                </ol>
                <ul>
                <li>Customer will dial *149*83# then select a plan they want to unsubscribe from.</li>
                </ul>
                <ol start="5">
                <li><strong><u>Via USSD Menu</u></strong></li>
                </ol>
                <ul>
                <li>Customer will dial *149*67# then select UNSUBSCRIBE.</li>
                </ul>


                <p><strong>6. Other Applicable Terms and Conditions</strong></p>
                <ul>
                    <li>These Terms and Conditions shall be read together with Vodacom General Subscriber Terms and Conditions.</li>
                    <li>In the event of inconsistence, overlapping or contradictory between these terms and conditions and Vodacom General Subscriber Terms and Conditions, the order of precedence and applicability shall be these Terms and Conditions Vodacom General Subscriber Terms and Conditions.</li>
                    <li>Any Subscriber and or Customer using the Services provided under these Terms and Conditions shall be deemed to have read and understood the Terms and Conditions.</li>
                    <li>We have a right to suspend the Services:
                    <ul>
                    <li>If You use the Service for any unauthorized purposes.</li>
                    <li>If we detect any abuse/misuse, breach of content, fraud or attempted fraud relating to your use of the Service.</li>
                    <li>If we are required or requested to do so to comply with an order or instruction of or a recommendation from the government, court, regulator, or other competent authority.</li>
                    <li>If we reasonably suspect or believe that you are in breach of these Conditions of Use.</li>
                    <li>If required to do so to address technical problems or for reasons of ensuring safety upon reasonable notice to you.</li>
                    <li>To facilitate update or upgrade the contents or functionality of the Service from time to time upon reasonable notice to you.</li>
                    <li>If we decide to suspend or cease the provision of the Service for commercial reasons or for any other reason as we may reasonably determine.</li>
                    </ul>
                    </li>
                </ul>

                <p><strong>7. Changes to These Terms and Conditions</strong></p>
                <ul>
                <li>We may add to or change this Conditions of Use in accordance with clause 7.2. We may add new fees and charges or change existing fees and charges at any time; as a direct result of new legislation, statutory instrument, Government regulations or licenses, rates of exchange, imposition, or alteration of government tax or as a result of any review of Vodacom&rsquo;s business planning, changes within the industry, recommendations from regulatory bodies or for such other reason as it may determine.</li>
                <li>We will let you know in advance if we add to or change these terms and conditions or if we add new fees and charges or change existing ones during or after the subscription of the Services. The amount and type of notice that we will give you will follow the necessary channel that apply and available at that time. (For example, we may notify you by letter, electronic mail, telephone (including recorded message or text SMS) or by an advertisement in gazette daily or weekly newspaper or in our website or any other means), you are advised also to visit our website from time to time to view the changes in case due to some unforeseen reasons you might not receive updates notification.</li>
                <li>If we change or add to these Terms and Conditions and you are not happy to accept the changes, you may terminate this Terms and Conditions as per Clause 8 below. If you do not ask us to end this Condition of Use and still using the Services, you are deemed to accept the changes on their effective date.</li>
                </ul>
               
                <p><strong>8. Termination of Services and or Conditions of Use</strong></p>
                <p>We may terminate this Conditions of Use at any time at our sole discretion. Such termination shall be subject to notifying you. Notification may be by letter, electronic mail, telephone (including recorded message or text SMS) or by an advertisement in gazette daily or weekly newspaper or in our website or any other means. You are advised also to visit our website from time to time to view the changes in case due to some unforeseen reasons you might not receive updates notification.</p>
                
                <p><strong>9. General</strong></p>
                <ul>
                    <li>We will not be liable for any delay or failure in performing any our obligations in respect of the use of the Services where such delay or failure occurs because of any circumstances beyond our Service&rsquo;s reasonable control.</li>
                    <li>You may not vary any of these Condition of Use and you may not transfer these terms to any other person or business.</li>
                    <li>These Conditions of Use shall be governed by and interpreted in accordance with Tanzania law.</li>
                    <li>We shall not be liable for any Indirect and or Consequential loss.</li>
                    <li>All communications under these Terms and Conditions shall be in either English or Swahili.</li>
                    <li>Customer Care
                      <ul>
                      <li>You may contact us through our customer care center lines 100 or other customer care contacts provided channels on our social media or Website (i.e., TOBi Online or Submit enquires) to report any disputes, claims or discrepancies in the Service.</li>
                      <li>Our customer care representatives will handle the reported case(s) in accordance with our standard complaint handling procedures available in our retail shops and on our website.</li>
                      <li>We may record or monitor phone calls to our Customer Care Center or any touch point with you for training purposes, to enable instructions to be verified and to assess whether our service standards are being met.</li>
                      <li>We may transfer to any other person any or all its rights and/or obligations under these Condition of Use subject to notifying you. Notwithstanding the foregoing, your legal rights will not be affected, and your obligations will not be increased as a result. You may not transfer your rights and obligations under these Condition of Use.</li>
                    </ul>
                    </li>
                </ul>

                <p><strong>10. Communication &amp; Complaints</strong></p>               
                <ul>
                <li>If you wish to enquire more details call through our customer service hotline as stated hereinabove</li>
                <li>If you are not satisfied with any aspect of the service offered, please contact Customer Care. They will be pleased to help and explain the complaints procedure in more detail. We will try and resolve your complaint as soon as possible. Often however, the complaint will need to be investigated. If this is the case, we will respond to you within 7 Business Days to acknowledge receipt of your complaint and we may respond to you how long it will take for your complaint to be resolved. If your complaint cannot be resolved, you will be advised accordingly.</li>
                </ul>
                </div>
              </>)}  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Terms