import {React, useState, useEffect} from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { NavLink } from 'react-router-dom';
import { getAllCompetition } from '../services/home';
import { useTranslation } from 'react-i18next';


const CountdownTimer = ({ targetDate,duration }) => {
  const { t } = useTranslation();
  const [complete, setComplete] = useState(false);
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      let timeLeft = {};
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
  
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearTimeout(timer);
    });
  
    const timerComponents = [];
  
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
  
      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]} {interval}{' '}
        </span>
      );
    });
  
    useEffect(() => {
     
          const interval = setInterval(() => {
          const currentTime = new Date().getTime();
          var startDate = new Date(targetDate);
          var startTimeWithOffset = startDate.getTime() + (duration * 1000);
          const elapsedTime = currentTime - startTimeWithOffset;
          const remaining = Math.max(duration - elapsedTime, 0);
          if (remaining === 0) {
            setComplete(true); 
            clearInterval(interval);
          }
        }, 1000);
    
        return () => clearInterval(interval);
      
    }, [targetDate,duration]);
    return (
      
      <>
        {timerComponents.length ? (
          <><div className='countdown-timer'> <img src="/image/chronometer.png" className='chronometer' />  {timerComponents}</div></> 
          
        ) : (
          complete ? (
            <div className='begin_the_competition'>The competition has concluded. Please check the results</div>
          ): (
            <div className='begin_the_competition'>{t('74')}</div>
          )
          
        )}
      </>
    );
  };
  
const AllQuizes = () => {
    const { t } = useTranslation();
    const [competition, setCompetition] = useState([]);
    useEffect(() => {
 
        getAllCompetition().then((res) => {
            setCompetition(res.data)
        }).catch((err) => {
            console.log(err)
        })
      
    }, [])
    
    const calculateTimeLeft = (start_date_time,duration) => {
      
        const currentTime = new Date().getTime();
        var startDate = new Date(start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, ""));
        var startTimeWithOffset = startDate.getTime() + (duration * 1000);
        const elapsedTime = currentTime - startTimeWithOffset;
        const remaining = Math.max(duration - elapsedTime, 0);

        if (remaining === 0) {
          return true;
        }
        return false;
    }
  
    

    return (
        <Layouts>
            <div className='home'>
                <div className="maine-div">
                    <div className="container box-wrapper">
                        <div class="header">
                            <div class="row align-items-center">
                                <div class="col-4 text-start">
                                    <div class="logo d-flex">
                                        <a href="#">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div class="col-8 text-end btnSearchBox">
                                   
                                </div>
                            </div>
                        </div>
                        <div class="page-title">
                            <div class="page-title-circle">
                                {/* <span class="qf-icon-list"></span> */}
                                <img src="/image/more.png" alt="Logo" />
                            </div>
                            <span>{t('66')}</span>
                        </div>
                        {competition && competition.length > 0 ? (
                            <ul class="camp-list-wrapper">
                                { 
                                    competition && competition.map((comp) => {
                                        return <li class="camp-list">
                                            
                                            <NavLink to={calculateTimeLeft(comp.start_date_time,comp.duration) ? `/championship/result/${comp.id}` : `/championship/detail/${comp.id}`}>

                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className='camp-img'>
                                                        <img src="/image/soon.png" />
                                                    </div>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className='camp-content'>
                                                    <div className='camp-title'> {comp.title}</div>
                                                    <div className='camp-description'>{comp.description}</div>
                                                    <div className='timer'> <CountdownTimer targetDate={new Date(comp.start_date_time.replace(/T/gi, " ").replace(/.000Z/gi, ""))} duration={comp.duration} /> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </NavLink>
                                        </li>
                                    })
                                }
                            </ul>
                        ) : (
                        <div className='featured-quiz pb-2 text-center custom-campionship'>
                            <img src="/image/soon.png" alt="Logo" className='rocket' />
                            <h1 className='ft-65'>{t('67')}</h1>
                            <p className='cg-wine mt-4'>{t('68')}</p>

                            
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default AllQuizes