// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';
/*import translationFR from '../locales/fr.json';
import translationAR from '../locales/ar.json';
import translationPG from '../locales/pg.json';*/
import translationSWC from '../locales/swc.json';
import {getFunFact} from '../services/home';

const data = await getFunFact().then((data) => {
  return data
})

console.log(data)
let totalFacts = 0
data.data.forEach((element, i) => {
  //console.log(element)
  totalFacts = totalFacts + 1
  translationEN = {...translationEN, [`fun_fact_${i+1}`]: element.english}
 /* translationFR = {...translationFR, [`fun_fact_${i+1}`]: element.french}
  translationAR = {...translationAR, [`fun_fact_${i+1}`]: element.arabic}
  translationPG = {...translationPG, [`fun_fact_${i+1}`]: element.portuguese}*/
  translationSWC = {...translationSWC, [`fun_fact_${i+1}`]: element.swahili}
});
localStorage.setItem("total_facts", totalFacts)

console.log(translationEN)

const resources = {
  0: {
    translation: translationEN,
  },
  /* 1: {
    translation: translationFR,
  },
  2: {
    translation: translationAR,
  },
  3: {
    translation: translationPG,
  },*/
  4: {
    translation: translationSWC,
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: '1', // default language
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  });

export default i18n;
