import logo from './logo.svg';
import './App.css';
//import WebRoute from './components/layouts';
import ProtectedRoute from './utils/protectedRoute';
import OpenRoute from './utils/openRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
function App() {
  const token = localStorage.getItem('token'); 
  return (
    <>
      {<I18nextProvider i18n={i18n}>
        <Router>{!token ? <OpenRoute /> : <ProtectedRoute />}</Router>
      </I18nextProvider> }
    </>
  );
}

export default App;
