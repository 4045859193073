import React from 'react'
import './css/screen3.css';  
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Screen3 = () => {
  const { t } = useTranslation();
  return ( 
    <div className='home'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
          <div class="page-header pb-5">
                <div class="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span class="modal-level-title levels-title">{t('13')}</span>
                </div>
            </div> 
             <div class="home-profile contact-us">
                  <h3 class="choose-title mb-3">{t('13')}:</h3>
                  <p>{t('49')}.</p>

                  <a href="tel:+100" class="info"> <img src="/image/call.png" /> 100</a>
                  {/* <a href="mailto:Contact@domain.com" class="info"><img src="/image/mail.png" /> Contact@domain.com</a> */}
                  <a href="https://api.whatsapp.com/send?phone=+255754100100&text=Hi" class="info"><img src="/image/whatsapp.png" /> 754100100 </a>
                  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default Screen3