import React, { useState, useEffect } from 'react'
import './css/screen3.css';  
import { getHistory } from '../services/home';
import { NavLink } from 'react-router-dom';
import {findLevel} from '../utils/levels'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {Loader} from "../utils/loader";


const History = () => {
    const [historyList, setHistoryList] = useState([]);
    const [suDetail, setSubDetail] = useState({subDate:"",points:0});
    const [level, setLevel] = useState(findLevel(1).name);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const currentLanguage = localStorage.getItem('selectedLanguage');
    useEffect(() => {
        setLoading(true);
        console.log(findLevel(1))
        getHistory(currentLanguage)
          .then((res) => {
            console.log(res);
            if(res.subDate)
            {
              setSubDetail({subDate:res.subDate,points:res.totalPoints});
              if(res.history) setHistoryList(res.history);
            }
            else setHistoryList(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
    }, [])
  return ( 
    loading ? <Loader />:
    <div className='history'> 
      <div className="maine-div">
          <div className="container-fluid box-wrapper difficult">  
            <div className="page-header pb-5">
                <div className="box-wrapper mx-auto text-center position-relative">
                    <NavLink to="/profile" className="page-header-back"><img src="/image/back.png" className='arrow' alt="Avatar" /></NavLink>
                    <span className="modal-level-title levels-title">{t('9')}</span>
                </div>
            </div> 
            <div className="card-simple-outer">
                <ul className="history-list-wrapper">
                 <li key="SubDate" className="history-list">
                      <div className="history-list-info">
                      <p><b>{t('94')}</b></p>
                      </div>
                      <div className="history-data">
                          <span> {suDetail.subDate}</span>
                      </div>
                  </li>
                  <li key="Points" className="history-list">
                      <div className="history-list-info">
                      <p><b>{t('95')}</b></p>
                      </div>
                      <div className="history-data">
                          <span>{suDetail.points}</span>
                      </div>
                  </li>
                </ul>
            </div>
            <div className="card-simple-outer">
                <ul className="history-list-wrapper">
                    {
                       historyList.length > 0 && historyList.map((history) => {
                           return <li key={history.id} className="history-list">
                                <div className="history-list-pict">
                                    <img src={history.quizView.image} alt="Quiz" /> 
                                </div>
                                <div className="history-list-info">
                                    <h4 className="history-list-info-title">{history.quizView.name}</h4>
                                    <p><b>{t('39')}:</b> {level} <span><b>{t('6')}:</b> {history.totalPoints}</span></p> 
                                </div>
                                <div className="history-event">
                                    <span>
                                    <img src="/image/event.png" alt="Tag" /> 
                                    {moment(history.createdAt).format('YYYY-MM-DD')}   
                                    </span>
                                </div>
                            </li>
                        })
                    }
                </ul>
                  
            </div> 
          </div>
      </div> 
    </div> 
  )
}

export default History